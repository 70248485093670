import { combineReducers } from "@reduxjs/toolkit";
import activeProjectReducer from "./reducer.activeProject";
import activeTranslationReducer from "./reducer.activeTranslation";
import appTransPartsReducer from "./reducer.appTransParts";
import authLoadedReducer from "./reducer.authLoaded";
import confirmReducer from "./reducer.confirm";
import countReducer from "./reducer.count";
import currentUserReducer from "./reducer.currentUser";
import customizeReducer from "./reducer.customize";
import editorOpenReducer from "./reducer.editorOpen";
import globalLoadingReducer from "./reducer.globalLoading";
import helpReducer from "./reducer.help";
import homeScreenReducer from "./reducer.homescreen";
import isChangedReducer from "./reducer.isChanged";
import isLoadingReducer from "./reducer.isLoading";
import loginFreezeReducer from "./reducer.loginFreeze";
import marketingReducer from "./reducer.marketing";
import projectsReducer from "./reducer.projects";
import selectionReducer from "./reducer.selection";
import settingsReducer from "./reducer.settings";
import showMainReducer from "./reducer.showMain";
import showSubReducer from "./reducer.showSub";
import statisticsReducer from "./reducer.statistics";
import tabIndexReducer from "./reducer.tabIndex";
import tabletrReducer from "./reducer.tabletr";
import tempLanguageReducer from "./reducer.tempLanguage";
import translationsReducer from "./reducer.translations";
import transLangReducer from "./reducer.transLang";
import updateOpenReducer from "./reducer.updateOpen";
import updatingReducer from "./reducer.updating";

const rootReducer = combineReducers({
  appTransParts: appTransPartsReducer,
  authLoaded: authLoadedReducer,
  confirm: confirmReducer,
  count: countReducer,
  currentUser: currentUserReducer,
  customize: customizeReducer,
  editorOpen: editorOpenReducer,
  globalLoading: globalLoadingReducer,
  help: helpReducer,
  home: homeScreenReducer,
  isChanged: isChangedReducer,
  isLoading: isLoadingReducer,
  loginFreeze: loginFreezeReducer,
  marketing: marketingReducer,
  projects: projectsReducer,
  activeProject: activeProjectReducer,
  activeTranslation: activeTranslationReducer,
  selection: selectionReducer,
  settings: settingsReducer,
  showMain: showMainReducer,
  showSub: showSubReducer,
  statistics: statisticsReducer,
  tabIndex: tabIndexReducer,
  tabletr: tabletrReducer,
  tempLanguage: tempLanguageReducer,
  translations: translationsReducer,
  transLang: transLangReducer,
  updateOpen: updateOpenReducer,
  updating: updatingReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
