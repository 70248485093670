import { onValue, ref } from "firebase/database";
import { database } from "firebaseUtil/firebaseUtil";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers/_index";
import { setStatistics } from "redux/reducers/reducer.statistics";
import store from "redux/store";

const AppStatisitics = (props: any) => {
  const currentUserId = useSelector<RootState, any>(
    (state: any) => state.currentUser?.id
  );
  const activeProjectId = useSelector<RootState, any>(
    (state: any) => state.activeProject?.id
  );

  useEffect(() => {
    if (currentUserId) {
      const countRef = ref(database, "users/" + currentUserId);

      const valueListener = onValue(countRef, (snapshot) => {
        const data = snapshot.val();

        store.dispatch(setStatistics(data || {}));
      });

      return () => {
        valueListener();
      };
    }
  }, [activeProjectId, currentUserId]);

  return <React.Fragment></React.Fragment>;
};

export default AppStatisitics;
