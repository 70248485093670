import React, { useEffect } from "react";
import styled from "styled-components";

const WrapperDiv = styled.div`
  margin-top: 0px;
  padding: 15px;
  padding-top: 0px;
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 45px;
  background: white;
`;

const TextDiv = styled.div`
  font-size: 18px;
  font-family: var(--font-normal);
  margin-bottom: 10px;
  line-height: 130%;
`;

const TextDivBold = styled.div`
  font-size: 18px;
  font-family: var(--font-normal);
  font-weight: 700;
  margin-bottom: 10px;
  line-height: 130%;
`;

const MainHeaderDiv = styled.div`
  font-size: 26px;
  font-family: var(--font-normal);
  font-weight: 700;
  margin-top: 25px;
  margin-bottom: 15px;
  line-height: 1.3;
  color: var(--toolbar-background);
`;
const HeaderDiv = styled.div`
  font-size: 24px;
  font-family: var(--font-normal);
  font-weight: 700;
  margin-top: 25px;
  margin-bottom: 15px;
  line-height: 1.3;
  color: var(--toolbar-background);
`;

const Terms = (props: any) => {
  const { tt } = props;

  useEffect(() => {
    if (props.subscription) {
      document.getElementById("subscription")?.scrollIntoView();
    }
  }, [props.subscription]);

  return (
    <React.Fragment>
      <WrapperDiv>
        <MainHeaderDiv>{tt("t1a", "Terms and conditions")}</MainHeaderDiv>
        <TextDiv>
          {tt(
            "t2aertasdwck5",
            "These General Terms and Conditions (the “Terms”) contain provisions regarding the right to use the inilingo.com website (the “Website”) and the knowledge sharing application (the “Software”) downloadable to Android, IOS devices and MacOS or Windows desktop or laptop computers, through which the website is available, and define the general terms and conditions of the software and website usage agreement (“Agreement”) between NetEnglish Solutions OÜ Hungarian Permanent Establishment (“Service Provider” or “Us”) and the user of the Service (“User” or “You”)."
          )}
        </TextDiv>
        <HeaderDiv id="subscription">
          {tt("t20ns", "Description of the Service")}
        </HeaderDiv>
        <TextDiv>
          {tt(
            "t23oaasawe",
            "IniLingo is a software service available on iOS, Android, Windows and MacOS platforms as well as on our website, which users may use for sharing knwowledge and create books which can be shared and sold."
          )}{" "}
          {tt(
            "t23oaasb",
            "Service Provider reserves the right to grant access to the Software and the service free of charge or as a paid service at its descretion."
          )}{" "}
          {tt(
            "t23oaasb23w3",
            "The Service provided by Service Provider (the “Service”) is the provision of the right to use the Software through which the User may use the Software for knowledge sharing and book creation and sale."
          )}{" "}
          {tt(
            "t23oaasccs",
            "In some areas new Users signing up for the first time will be granted a free trial with all functionality of IniLingo available."
          )}{" "}
          {tt(
            "t23oaasdksd",
            "Continued use of the IniLingo application and service may require the User to sign up for a paid subscription."
          )}{" "}
        </TextDiv>
        <HeaderDiv>
          {tt("t13awe", "Service Provider's name and contact data")}
        </HeaderDiv>
        <TextDiv>
          {tt(
            "t214acxw",
            "*Name:* NetEnglish Solutions OÜ Hungarian Permanent Establishment."
          )}
        </TextDiv>
        <TextDiv>
          {tt(
            "t214aacxw",
            "*Address:* 9700 Hungary, Szombathely, Deák Ferenc utca 29."
          )}
        </TextDiv>
        <TextDiv>{tt("t215acxer", "*Website:* https://inilingo.com")}</TextDiv>
        <TextDiv>{tt("t216acx", "*Tax Number:* 27972984-2-18")}</TextDiv>
        <TextDiv>{tt("t217acxwe", "*E-mail:* support@inilingo.com")}</TextDiv>
        <TextDiv>
          {tt("t218acx", "*Company Registry Number:* 18-17-000025")}
        </TextDiv>
        <TextDiv>
          {tt(
            "t219acx",
            "*Hosted by:* Google Cloud Hosting (support@google.com)"
          )}
        </TextDiv>
        <HeaderDiv>{tt("t13aweww0", "Subject of the Agreement")}</HeaderDiv>
        <TextDiv>
          {tt(
            "st13aweww1",
            "Service Provider grants the User the right to use the Software and the service under the Agreement."
          )}{" "}
          {tt(
            "st13aweww2",
            "The subject of this Agreement is your right to use the Software and the service."
          )}{" "}
          {tt(
            "st13aweww3",
            "The Agreement does not cover the distribution, modification (including, but not limited to its translation), processing, analysis, reverse engineering, and / or any other modification of the Software, including the correction of any errors, and the reproduction of the results of such activites."
          )}
        </TextDiv>
        <TextDiv>
          {tt(
            "bst13aweww3klm",
            "By providing the right to use the Software and operating the Website, the Service Provider enables the user to create books and share them with others for free or for money."
          )}
        </TextDiv>
        <TextDiv>
          {tt(
            "b1st13aweww3",
            "The User may not grant permission to third parties to use the Software and the service."
          )}
        </TextDiv>
        <TextDiv>
          {tt(
            "b2st13aweww3",
            "Service Provider warrants that no third party has any rights that would restrict User's usage rights."
          )}
        </TextDiv>
        <TextDiv>
          {tt(
            "b3st13aweww3kklm",
            "The Agreement covers a one-week free trial and then monthly paid subscription for full use of the service as laid out on its website or limited free usage entirely at the discretion of the Service Provider. Service Provider may at its own discretion offer longer free usage periods."
          )}
        </TextDiv>
        <TextDiv>
          {tt(
            "b4st13aweww3",
            "The User is obliged to use the software and the Service legally and as intended."
          )}
        </TextDiv>
        <HeaderDiv>{tt("t4a", "Accounts and membership")}</HeaderDiv>
        <TextDiv>
          {tt(
            "t5a",
            "If you create an account on the Website or in our applications, you are responsible for maintaining the security of your account and you are fully responsible for all activities that occur under the account and any other actions taken in connection with it."
          )}{" "}
          {tt(
            "t6a",
            "We may, but have no obligation to, monitor and review new accounts before you may sign in and use our Services."
          )}{" "}
          {tt(
            "t7a",
            "Providing false contact information of any kind may result in the termination of your account."
          )}{" "}
          {tt(
            "t8a",
            "You must immediately notify us of any unauthorized uses of your account or any other breaches of security."
          )}{" "}
          {tt(
            "t9a",
            "We will not be liable for any acts or omissions by you, including any damages of any kind incurred as a result of such acts or omissions."
          )}{" "}
          {tt(
            "t10a",
            "We may suspend, disable, or delete your account (or any part thereof) if we determine that you have violated any provision of these Terms or that your conduct or content would tend to damage our reputation and goodwill."
          )}{" "}
          {tt(
            "t11a",
            "If we delete your account for the foregoing reasons, you may not re-register for our Services."
          )}{" "}
          {tt(
            "t12a",
            "We may block your email address and Internet protocol address to prevent further registration."
          )}
        </TextDiv>
        <HeaderDiv>
          {tt("b5st13aweww3", "Use of the Website; Registration")}
        </HeaderDiv>
        <HeaderDiv>
          {tt(
            "ab5st13aweww3",
            "Conclusion of the Agreement; Placing the Order"
          )}
        </HeaderDiv>
        <TextDiv>
          {tt(
            "ab5st13aweww3as",
            "The Usage Agreement is considered to be an electronically concluded contract between absentees."
          )}{" "}
          {tt(
            "helyesas",
            "The resulting Agreement will be electronically filed in our database and will be retained for 10 years from the date of delivery."
          )}
        </TextDiv>
        <TextDiv>
          {tt(
            "chelyesas1",
            "Signing up for an account entails acceptance of this Agreement and it happens through the website or the application provided by clicking on the Sign Up button and supplying basic user information then accepting these Terms and Conditions and the Privacy Policy."
          )}{" "}
          {tt(
            "chelyesas2",
            "This contract is valid from the time of pressing the Sign Up button."
          )}{" "}
        </TextDiv>
        <TextDiv>
          {tt(
            "achelyesas3",
            "Users then start a one-week trial period at the end of which they can sign up for a monthly subscription using the subscription service of Google Play, App Store or Paddle.com, NetEnglish Solutions OÜ’s Merchant of Record or continue to use the software free with limitations laid out on the IniLingo website."
          )}
        </TextDiv>
        <TextDiv>
          {tt(
            "chelyesas5",
            "The subscription price shown is the price the user is required to pay and it contains applicable taxes."
          )}{" "}
          {tt(
            "chelyesas6",
            "The User will be debited the exact accepted monthly fee on the same day of each month."
          )}{" "}
          {tt(
            "helyesas7",
            "Terms of this agreement referencing paid subscritions are valid from the time of submitting the payment for approval."
          )}
        </TextDiv>
        <TextDiv>
          {tt(
            "cchelyesas5",
            "If the selected payment method doesn’t work on one of the subscription renewal dates, the user will receive a three day grace period to correct the payment method or submit a new one."
          )}{" "}
          {tt(
            "cchelyesas6",
            "If the situation is not handled the user’s subscription is suspended which means that IniLingo will limit its accessible features to those available to free users."
          )}
        </TextDiv>
        <TextDiv>
          {tt(
            "helyesas8",
            "In case either the Service Provider or the User later finds some of the data provided at the time of accepting these terms were incorrect, user or Service Provider can initiate changes in them by sending E-mails to the other party."
          )}
        </TextDiv>

        <HeaderDiv>
          {tt("t20sweawe", "Subscription Fees, Billing and payments")}
        </HeaderDiv>
        <TextDiv>
          {tt(
            "t21accwe",
            "You shall pay all Subscription Fees in accordance with the fees, charges, and billing terms in effect at the time a fee or charge is due and payable."
          )}{" "}
          {tt(
            "t21bwewewewe",
            "You can make payments through the Google Play and App Store subscription service, or our Merchant of Record with Visa and Mastercard cards, PayPal or (depending on your location) other payment methods."
          )}{" "}
          {tt(
            "t21wesbwewe",
            "The Subscription Fee includes VAT as required by law for your country and Hungary."
          )}{" "}
        </TextDiv>
        <TextDiv>
          {tt(
            "t21apowdc",
            "Google Play, the Apple App Store or 2checkcout (our Merchant of Record) will create and send out an invoice to the User about the License Fee to the specified e-mail address."
          )}{" "}
          {tt(
            "t21apowerwerxxxxxxx",
            "If you provide an incorrect email address and we receive notification about it, we will re-send the invoice to the correct address, but providing a correct email address is the responsibility of the User, and the Service Provider takes no responsibility for errors in service or invoicing resulting from incorrect email addresses provided."
          )}
        </TextDiv>
        <TextDiv>
          {tt(
            "t24cwew",
            "We reserve the right to change products and product pricing at any time with a 30-day notice."
          )}{" "}
          {tt(
            "t25dwewcwe",
            "Service Provider reserves the right to refuse an order if it makes unusual claims beyond what is set forth in these Terms, or if Service Provider can assume that User wishes to use the Service to perform the prohibited activities set forth in these Terms."
          )}{" "}
          {tt(
            "t26e",
            "In the event that we make a change to or cancel an order, we will always attempt to notify you by contacting the e-mail and/or billing address/phone number provided at the time the order was made."
          )}{" "}
        </TextDiv>
        <HeaderDiv>{tt("payt20aui", "Payment Methods")}</HeaderDiv>
        <TextDiv>
          {tt(
            "payt523fwewewerwer",
            "Payment on the Website is made by our contracted resellers, Paddle.com Market Ltd. or Apple App Store or Google Play through their own pages."
          )}{" "}
          {tt(
            "payt523fweweas",
            "We do not receive or store your credit card or payment information."
          )}{" "}
        </TextDiv>

        <HeaderDiv>{tt("t127u", "Refund policy")}</HeaderDiv>
        <TextDiv>
          {tt(
            "t128v",
            "We're so convinced you'll absolutely love our service, that we're willing to offer a 14 day risk-free money back guarantee."
          )}{" "}
          {tt(
            "t128wwewwdc",
            "If you are not satisfied with the Service for any reason you can ask a refund of the last month’s subscription fee within 14 days of your payment."
          )}{" "}
          {tt(
            "t129d",
            "To request a refund clearly express your wish in an email sent to [*support@inilingo.com*] from the email address you use for your subscription."
          )}{" "}
        </TextDiv>
        <TextDiv>
          {tt(
            "t130dcwew",
            "Refunds will be initiated by the Service Provider within three working days after receiving your email through standard bank procedures and the sum of your last payment will be credited to the User's account. Length of processing depends on your card issuer or bank."
          )}{" "}
          {tt(
            "t131dewewe",
            "To avoid abuse, the Service Provider maintains the right to refuse selling further subscriptions to those who requested and got a refund."
          )}{" "}
        </TextDiv>
        <HeaderDiv>{tt("t13art", "User content")}</HeaderDiv>
        <TextDiv>
          {tt(
            "t14a",
            "We do not own any data, information or material (“Content”) that you submit on the Website or through the Software in the course of using the Service."
          )}{" "}
          {tt(
            "t15a",
            "You shall have sole responsibility for the accuracy, quality, integrity, legality, reliability, appropriateness, and intellectual property ownership or right to use of all submitted Content."
          )}{" "}
          {tt(
            "t16a",
            "We may monitor and review Content on the Website submitted or created using our Services by you but we are not obligated to."
          )}{" "}
          {tt(
            "t17a",
            "You grant us permission to access, copy, distribute, store, transmit, reformat, display and perform the Content of your user account solely as required for the purpose of providing the Services to you."
          )}{" "}
          {tt(
            "t18a",
            "Without limiting any of those representations or warranties, we have the right, though not the obligation, to, in our own sole discretion, refuse or remove any Content that, in our reasonable opinion, violates any of our policies or is in any way harmful or objectionable."
          )}{" "}
          {tt(
            "t19a",
            "Unless specifically permitted by you, your use of the Service does not grant us the license to use, reproduce, adapt, modify, publish or distribute the Content created by you or stored in your user account for commercial, marketing or any similar purpose."
          )}
        </TextDiv>
        <HeaderDiv>{tt("t27a", "Accuracy of information")}</HeaderDiv>
        <TextDiv>
          {tt(
            "t28wewa",
            "Occasionally there may be information on the Website and in the Application that contains typographical errors, inaccuracies or omissions that may relate to product descriptions, pricing, availability, promotions and offers."
          )}{" "}
          {tt(
            "t29d",
            "We reserve the right to correct any errors, inaccuracies or omissions, and to change or update information or cancel orders if any information on the Website or on any related Service is inaccurate at any time without prior notice (including after you have submitted your order)."
          )}{" "}
          {tt(
            "t30d",
            "We undertake no obligation to update, amend or clarify information on the Website including, without limitation, pricing information, except as required by law."
          )}{" "}
          {tt(
            "t31d",
            "No specified update or refresh date applied on the Website should be taken to indicate that all information on the Website or on any related Service has been modified or updated."
          )}
        </TextDiv>
        <HeaderDiv>{tt("t32d", "Backups")}</HeaderDiv>
        <TextDiv>
          {tt(
            "t33d",
            "We perform regular backups of the Website and Content, however, these backups are for our own administrative purposes only and are in no way guaranteed. You are responsible for maintaining your own backups of your data."
          )}{" "}
          {tt(
            "t34d",
            "We do not provide any sort of compensation for lost or incomplete data in the event that backups do not function properly."
          )}{" "}
          {tt(
            "t35d",
            "We will do our best to ensure complete and accurate backups, but assume no responsibility for this duty."
          )}
        </TextDiv>
        <HeaderDiv>{tt("t36d", "Links to other websites")}</HeaderDiv>
        <TextDiv>
          {tt(
            "t37dwwsc",
            "Although the Website and the Software may link to other websites, we are not, directly or indirectly, implying any approval, association, sponsorship, endorsement, or affiliation with any linked website, unless specifically stated herein."
          )}{" "}
          {tt(
            "t38d",
            "We are not responsible for examining or evaluating, and we do not warrant the offerings of, any businesses or individuals or the content of their websites."
          )}{" "}
          {tt(
            "t39d",
            "We do not assume any responsibility or liability for the actions, products, services, and content of any other third parties."
          )}{" "}
          {tt(
            "t40d",
            "You should carefully review the legal statements and other conditions of use of any website which you access through a link from this Website."
          )}{" "}
          {tt(
            "t41d",
            "Your linking to any other off-site websites is at your own risk."
          )}
        </TextDiv>
        <HeaderDiv>{tt("t42d", "Prohibited uses")}</HeaderDiv>
        <TextDiv>
          {tt(
            "t43d",
            "In addition to other terms as set forth in these Terms, you are prohibited from using the Website or its Content:"
          )}{" "}
          {tt(
            "t44d",
            "(a) for any unlawful purpose; (b) to solicit others to perform or participate in any unlawful acts; (c) to violate any international, federal, provincial or state regulations, rules, laws, or local ordinances;"
          )}{" "}
          {tt(
            "t45d",
            "(d) to infringe upon or violate our intellectual property rights or the intellectual property rights of others; (e) to harass, abuse, insult, harm, defame, slander, disparage, intimidate, or discriminate based on gender, sexual orientation, religion, ethnicity, race, age, national origin, or disability;"
          )}{" "}
          {tt(
            "t46d",
            "(f) to submit false or misleading information; (g) to upload or transmit viruses or any other type of malicious code that will or may be used in any way that will affect the functionality or operation of the Service or of any related website, other websites, or the Internet;"
          )}{" "}
          {tt(
            "t47d",
            "(h) to spam, phish, pharm, pretext, spider, crawl, or scrape; (i) for any obscene or immoral purpose; or (j) to interfere with or circumvent the security features of the Service or any related website, other websites, or the Internet."
          )}{" "}
          {tt(
            "t48d",
            "We reserve the right to terminate your use of the Service or any related website for violating any of the prohibited uses."
          )}
        </TextDiv>
        <HeaderDiv>{tt("t49d", "Intellectual property rights")}</HeaderDiv>
        <TextDiv>
          {tt(
            "t50d",
            "“Intellectual Property Rights” means all present and future rights conferred by statute, common law or equity in or in relation to any copyright and related rights, trademarks, designs, patents, inventions, goodwill and the right to sue for passing off, rights to inventions, rights to use, and all other intellectual property rights, in each case whether registered or unregistered and including all applications and rights to apply for and be granted, rights to claim priority from, such rights and all similar or equivalent rights or forms of protection and any other results of intellectual activity which subsist or will subsist now or in the future in any part of the world."
          )}{" "}
          {tt(
            "t51d",
            "This License Agreement does not transfer to you any intellectual property owned by the Service Provider or third parties, and all rights, titles, and interests in and to such property will remain (as between the parties) solely with the Service Provider."
          )}{" "}
          {tt(
            "t52d",
            "All trademarks, service marks, graphics and logos used in connection with the Website or Services, are trademarks or registered trademarks of the Service Provider or the licensors of the Service Provider."
          )}{" "}
          {tt(
            "t53d",
            "Other trademarks, service marks, graphics and logos used in connection with the Website or Services may be the trademarks of other third parties."
          )}{" "}
          {tt(
            "t54d",
            "Your use of the Website and Services grants you no right or license to reproduce or otherwise use any Service Provider or third party trademarks."
          )}
        </TextDiv>
        <HeaderDiv>{tt("t55d", "Warranty")}</HeaderDiv>
        <TextDiv>
          {tt(
            "t56d",
            "You agree that this Service is provided on an “as is” and “as available” basis and that your use of our Website, Services and Softwares is solely at your own risk."
          )}{" "}
          {tt(
            "t57dasdfasdf",
            "We do not guarantee that the Service will meet your needs, nor do we guarantee any results from the use of the Service."
          )}{" "}
          {tt(
            "t59d",
            "You understand and agree that any material and/or data downloaded or otherwise obtained through the use of Service is done at your own discretion and risk and that you will be solely responsible for any damage or loss of data that results from the download of such material and/or data."
          )}{" "}
          {tt(
            "t60dwesx",
            "We do not warrant the effectiveness of your use of the Service other than the full 14-day money back guarantee described above."
          )}{" "}
          {tt(
            "t61d",
            "No advice or information, whether oral or written, obtained by you from us or through the Service shall create any warranty not expressly made herein."
          )}
        </TextDiv>

        <HeaderDiv>{tt("bagt61d", "Supplies warranty")}</HeaderDiv>
        <TextDivBold>
          {tt(
            "bagt61d1",
            "• In what cases can you exercise your right to warranty for supplies?"
          )}
        </TextDivBold>
        <TextDiv>
          {tt(
            "bagt61d2",
            "In the event of incorrect performance of the Service Provider, you may assert a warranty claim against the Service Provider in accordance with the rules of the Hungarian Civil Code."
          )}
        </TextDiv>
        <TextDivBold>
          {tt(
            "bagt61d3",
            "• What rights do you have based on your supplies warranty claim?"
          )}
        </TextDivBold>
        <TextDiv>
          {tt(
            "bagt61d4",
            "You can choose to have the following supplies warranty claims:"
          )}
        </TextDiv>
        <TextDiv>
          {tt(
            "bagt61d5",
            "In case of possible defective performance, no repair or replacement may be requested due to the nature of the Service."
          )}{" "}
          {tt(
            "bagt61d6",
            "As a warranty claim, you may request a pro rata reduction of the Subscription Fee or, ultimately, withdraw from the Agreement."
          )}
        </TextDiv>

        <TextDivBold>
          {tt(
            "bagt61d7",
            "• What is the deadline for you to assert your warranty claim?"
          )}
        </TextDivBold>

        <TextDiv>
          {tt(
            "bagt61d8",
            "You must report the defect to Service Provider without delay upon discovery."
          )}{" "}
          {tt(
            "bagt61d9",
            "An error reported within 2 (two) months from the discovery of the error shall be deemed to have been reported without delay."
          )}{" "}
          {tt(
            "bagt61d10",
            "You will be liable for any damages resulting from the delay in the communication, it cannot be transferred to the Service Provider."
          )}
        </TextDiv>

        <TextDiv>
          {tt(
            "bagt61d11",
            "We would like to inform and draw your attention to the fact that you may no longer exercise your warranty rights beyond the limitation period of 2 (two) years from the execution of the Agreement."
          )}
        </TextDiv>

        <TextDivBold>
          {tt(
            "bagt61d12",
            "• Against whom can you assert your warranty claim?"
          )}
        </TextDivBold>

        <TextDiv>
          {tt(
            "bagt61d13",
            "You can enforce your warranty claim against Service Provider."
          )}
        </TextDiv>

        <HeaderDiv>{tt("t62d", "Limitation of liability")}</HeaderDiv>
        <TextDiv>
          {tt(
            "t63d",
            "To the fullest extent permitted by applicable law, in no event will Service Provider, its affiliates, directors, officers, employees, agents, suppliers or licensors be liable to any person for any indirect, incidental, special, punitive, cover or consequential damages (including, without limitation, damages for lost profits, revenue, sales, goodwill, use of content, impact on business, business interruption, loss of anticipated savings, loss of business opportunity) however caused, under any theory of liability, including, without limitation, contract, tort, warranty, breach of statutory duty, negligence or otherwise, even if the liable party has been advised as to the possibility of such damages or could have foreseen such damages."
          )}{" "}
          {tt(
            "t64d",
            "To the maximum extent permitted by applicable law, the aggregate liability of Service Provider and its affiliates, officers, employees, agents, suppliers and licensors relating to the services will be limited to an amount greater of one dollar or any amounts actually paid in cash by you to Service Provider for the prior one month period prior to the first event or occurrence giving rise to such liability."
          )}{" "}
          {tt(
            "t65d",
            "The limitations and exclusions also apply if this remedy does not fully compensate you for any losses or fails of its essential purpose."
          )}
        </TextDiv>
        <HeaderDiv>{tt("t66d", "Severability")}</HeaderDiv>
        <TextDiv>
          {tt(
            "t67d",
            "All rights and restrictions contained in these Terms may be exercised and shall be applicable and binding only to the extent that they do not violate any applicable laws and are intended to be limited to the extent necessary so that they will not render this Agreement illegal, invalid or unenforceable."
          )}{" "}
          {tt(
            "t68d",
            "If any provision or portion of any provision of these Terms shall be held to be illegal, invalid or unenforceable by a court of competent jurisdiction, it is the intention of the parties that the remaining provisions or portions thereof shall constitute part of the Agreement, and all such remaining provisions or portions thereof shall remain in full force and effect."
          )}
        </TextDiv>
        <HeaderDiv>
          {tt("t69daa", "Handling of Complaints; Dispute resolution")}
        </HeaderDiv>
        <TextDiv>
          {tt(
            "t70da",
            "Service Provider tries to resolve any problems and disputes arising during the use of our Services peacefully. We encourage you to always contact us with any problem you might have through our email address (support@inilingo.com)."
          )}
        </TextDiv>
        <TextDivBold>{tt("monkey3", "• Complaint handling")}</TextDivBold>

        <TextDiv>
          {tt(
            "monkey31",
            "In case of a complaint related to the Service, you can report it at the following contact details:"
          )}
        </TextDiv>

        <TextDiv>
          {tt(
            "monkey32",
            "*Name:* NetEnglish Solutions OÜ Hungarian Permanent Establishment"
          )}
        </TextDiv>
        <TextDiv>
          {tt(
            "monkey33",
            "*Address:* 9700 Hungary, Szombathely, Deák Ferenc utca 29."
          )}
        </TextDiv>
        <TextDiv>
          {tt("monkey34", "*Electronic contact:* support@inilingo.com")}
        </TextDiv>

        <TextDiv>
          {tt(
            "monkey35",
            "The Complainant will review the complaint within 30 days and remedy it as necessary."
          )}
        </TextDiv>

        <TextDiv>
          {tt(
            "monkey36",
            "The Service Provider will take minutes of the complaint and its position on it, and will send a copy to you within 30 days of the complaint being filed, together with a substantive response."
          )}
        </TextDiv>

        <TextDiv>
          {tt(
            "monkey37",
            "If your complaint is rejected, you can take your complaint to court or initiate proceedings before a conciliation body."
          )}
        </TextDiv>

        <TextDivBold>
          {tt(
            "monkey38",
            "• Initiation of proceedings before a consumer protection authority"
          )}
        </TextDivBold>

        <TextDiv>
          {tt(
            "monkey39",
            "If you notice a violation of your rights, you have the right to complain to the district office responsible for your place of residence, as the consumer protection authority."
          )}{" "}
          {tt(
            "monkey310",
            "The contact details of the district offices are available through the following link: http://jarasinfo.gov.hu/jarasok-lista"
          )}
        </TextDiv>

        <TextDivBold>
          {tt(
            "monkey311",
            "• Initiation of proceedings before the Conciliation Body"
          )}
        </TextDivBold>

        <TextDiv>
          {tt(
            "monkey312",
            "For the out-of-court settlement of a consumer dispute, you may bring proceedings before the conciliation body competent for your place of residence or stay."
          )}{" "}
          {tt(
            "monkey313",
            "The task of the conciliation body is to try to reach an agreement between the parties in order to settle the consumer dispute, failing which it will decide the matter in order to ensure simple, fast, efficient and cost-effective enforcement of consumer rights."
          )}{" "}
          {tt(
            "monkey314",
            "At the request of the consumer or the business, the conciliation body shall provide advice on the consumer's rights and obligations."
          )}
        </TextDiv>

        <TextDiv>
          {tt(
            "monkey315",
            "You can contact the conciliation bodies in each county via the following link:"
          )}{" "}
          {tt("monkey316", "http://www.bekeltetes.hu/index.php?id=testuletek")}
        </TextDiv>

        <TextDiv>
          {tt(
            "monkey317",
            "Contact details of the conciliation body competent according to the registered office of the Service Provider:"
          )}
        </TextDiv>

        <TextDiv>
          {tt(
            "monkey318",
            "Conciliation Board operating under the Vas County Chamber of Commerce and Industry"
          )}
        </TextDiv>

        <TextDivBold>
          {tt("monkey319", "Central contacts, mailing address:")}
        </TextDivBold>
        <TextDiv>
          {tt("monkey320", "*Address:* 9700 Szombathely, Honvéd tér 2.")}
        </TextDiv>
        <TextDiv>
          {tt("monkey321", "*Phone number:* +36 94/506-645, +36 94/312-356")}
        </TextDiv>
        <TextDiv>{tt("monkey322", "*E-mail:* pergel.bea@vmkik.hu")}</TextDiv>
        <TextDiv>
          {tt(
            "monkey323",
            "*Administrator:* Pergel Bea Conciliation Board member"
          )}
        </TextDiv>

        <TextDiv>
          {tt("monkey324", "*Website:* http://vasibekelteto.hu/")}
        </TextDiv>

        <TextDivBold>
          {tt(
            "monkey325",
            "• Settlement of disputes related to the service purchased online"
          )}
        </TextDivBold>

        <TextDiv>
          {tt(
            "monkey326",
            "In order to facilitate the out-of-court settlement of disputes, you are informed that in the event of a complaint about services purchased online, you may initiate out-of-court settlement of your dispute through a platform set up by the European Commission."
          )}{" "}
          {tt(
            "monkey327",
            "You can access the dispute resolution forum at the following link:"
          )}
        </TextDiv>
        <TextDiv>
          {tt(
            "monkey328",
            "https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home.chooseLanguage"
          )}
        </TextDiv>

        <TextDiv>
          {tt(
            "monkey329",
            "You can submit your complaint on the online dispute resolution platform by filling out the form."
          )}{" "}
          {tt(
            "monkey330",
            "It is advisable to attach to the form any document that supports your complaint."
          )}
        </TextDiv>
        <HeaderDiv>{tt("t69dawerw", "Jurisdiction")}</HeaderDiv>
        <TextDiv>
          {tt(
            "t70dr",
            "Should the dispute stay unresolved, the formation, interpretation, and performance of these Terms and any disputes arising out of it shall be governed by the substantive and procedural laws of Hungary without regard to its rules on conflicts or choice of law and, to the extent applicable, the laws of Hungary."
          )}{" "}
          {tt(
            "t71de",
            "The exclusive jurisdiction and venue for actions related to the subject matter hereof shall be the courts located in Hungary, and you hereby submit to the personal jurisdiction of such courts."
          )}{" "}
        </TextDiv>
        <HeaderDiv>{tt("sat73d", "Changes and amendments")}</HeaderDiv>
        <TextDiv>
          {tt(
            "t74d",
            "We reserve the right to modify these Terms or its policies relating to the Website or Service at any time, effective upon posting of an updated version of these Terms on the Website."
          )}{" "}
          {tt(
            "t75d",
            "When we do, we will revise the updated date at the bottom of this page."
          )}{" "}
          {tt(
            "t76d",
            "Continued use of the Website and the Service after any such changes shall constitute your consent to such changes."
          )}
        </TextDiv>
        <HeaderDiv>{tt("t77d", "Acceptance of these terms")}</HeaderDiv>
        <TextDiv>
          {tt(
            "t78d",
            "You acknowledge that you have read these Terms and agree to all its terms and conditions."
          )}{" "}
          {tt(
            "t79d",
            "By using the Website, our Softwares or its Services you agree to be bound by these Terms."
          )}{" "}
          {tt(
            "t80d",
            "If you do not agree to abide by these Terms, you are not authorized to use or access the Website and/or the Services."
          )}
        </TextDiv>
        <HeaderDiv>{tt("t81d", "Contacting us")}</HeaderDiv>
        <TextDiv>
          {tt(
            "t82d",
            "If you would like to contact us to understand more about these Terms or wish to contact us concerning any matter relating to it, you may send an email to [*support@inilingo.com*]."
          )}
        </TextDiv>
        <TextDiv>
          {tt("t83dwe16a", "This document was last updated on March 21, 2022")}
        </TextDiv>
      </WrapperDiv>
    </React.Fragment>
  );
};

export default Terms;
