import { setAuthLoaded } from "redux/reducers/reducer.authLoaded";
import { setCurrentUser } from "redux/reducers/reducer.currentUser";
import { setSettings } from "redux/reducers/reducer.settings";
import store from "redux/store";

// import { firestore } from "../firebaseUtil/firebaseUtil";
import { firestore } from "../firebaseUtil/firebaseUtil";

import {
  collection,
  doc,
  DocumentData,
  DocumentReference,
  DocumentSnapshot,
  onSnapshot,
  setDoc,
} from "firebase/firestore";
import { setProjects } from "redux/reducers/reducer.projects";

import { setActiveProject } from "redux/reducers/reducer.activeProject";
import { setEditorOpen } from "redux/reducers/reducer.editorOpen";

const defaultSettings = {
  testReview: false,
  autoRead: true,
  pronunciation: true,
  writing: true,
  readSpeed: 1,
  playSpeed: 1,
  pro: true,
  startingLevel: "A",
  showFirstPage: true,
  wordLevel: "A",
  playing: true,
  highlight: true,
  fontSize: 18,
  head: true,
  trialUser: false,
  hideTutorial: false,
  autoTexts: [],
  testRepetitions: 5,
  exeRepetitions: 3,
  status: { status: "books" },
  theme: "blue",
};

let userListener: any = null;
let projectListener: any = null;
let trials = 0;

export const setupListeners = (userRef: DocumentReference<DocumentData>) => {
  //********************************
  //** User Snapshot
  //********************************
  console.log("start setting up listeners");

  userListener = onSnapshot(
    userRef,
    async (snapShot: DocumentSnapshot) => {
      trials = 0;

      if (snapShot.data()) {
        store.dispatch(
          setCurrentUser({
            ...snapShot.data(),
            id: userRef.id,
          })
        );
      } else {
        // console.log("bubornyak");
        store.dispatch(setCurrentUser(null));
        return;
      }

      const settingsTemp = localStorage.getItem(`ne_settings${userRef.id}`);

      if (settingsTemp) {
        store.dispatch(setSettings(JSON.parse(settingsTemp)));
      }

      setupCombinedListener(userRef.id);

      localStorage.setItem("currentUser", userRef.id);
      localStorage.setItem("userType", snapShot.data()?.type);
      localStorage.setItem("userLang", snapShot.data()?.language);

      store.dispatch(setAuthLoaded(true));

      trials = 0;
    },
    (error) => {
      console.log("this is the userlistener errror");
      if (error.code === "permission-denied" && trials < 3) {
        console.log(userRef.id);
        // setTimeout(() => setupListeners(userRef), 100);
        trials++;
      }

      console.log("userlistener", error);
    }
  );
};

let combinedListener: any = null;

export const loadProject = async (
  user: any,
  project: any,
  owner: string = ""
) => {
  if (projectListener) {
    projectListener();
  }

  const projectRef = doc(
    firestore,
    `/users/${owner || user}/data/projects/projects/${project}`
  );

  try {
    projectListener = onSnapshot(
      projectRef,
      (snapShot: any) => {
        console.log("snapshot");
        if (snapShot.data()) {
          let myLanguages: any = snapShot.data().languages;

          if (owner) {
            myLanguages = {};
            const languages = store
              .getState()
              .projects.find(
                (item: any) => item.id === snapShot.data().id
              )?.languages;
            if (!languages) {
              console.log("nolang");
              projectListener();
              store.dispatch(setActiveProject({}));
              return;
            }

            languages.forEach((item: any) => {
              myLanguages[item.id] = snapShot.data().languages[item.id];
            });
          }

          store.dispatch(
            setActiveProject({
              ...snapShot.data(),
              id: snapShot.id,
              languages: myLanguages,
              owner: owner || "",
            })
          );
        }
      },
      () => {
        store.dispatch(setEditorOpen(false));
      }
    );
  } catch (e) {
    console.log(e);
  }
};

export const setupCombinedListener = (userId: string) => {
  if (combinedListener) {
    combinedListener();
  }

  if (!userId) {
    return;
  }

  let userDataRef: any = undefined;

  userDataRef = collection(firestore, `/users/${userId}/data`);

  combinedListener = onSnapshot(
    userDataRef,
    async (snapShot: any) => {
      snapShot.docChanges().forEach((change: any) => {
        switch (change.doc.id) {
          case "projects":
            const tempProjects: any = change.doc.data() || {};
            const projects: any = Object.keys(tempProjects).map((key) => ({
              ...tempProjects[key],
              id: key,
              languages: Object.keys(tempProjects[key].languages || {}).map(
                (item: any) => tempProjects[key].languages[item]
              ),
            }));

            store.dispatch(setProjects(projects));
            break;
          case "settings":
            store.dispatch(
              setSettings({
                ...((change.doc.data() as any) || {
                  ...defaultSettings,
                }),
                loaded: true,
              })
            );
            if (change.doc.data().showFirstPage === undefined) {
              setDoc(
                doc(firestore, `/users/${userId}/data/settings`),
                defaultSettings
              );
            }
            localStorage.setItem(
              `ne_settings${userId}`,
              JSON.stringify(change.doc.data() || defaultSettings)
            );
            return;
        }
      });
    },
    (error: any) => {
      console.log("combined listener setup error", error);
    }
  );

  return combinedListener;
};

export const cleanListeners = () => {
  console.log("cleaningListeners");

  if (userListener) {
    userListener();
    userListener = null;
  }

  if (combinedListener) {
    combinedListener();
    combinedListener = null;
  }

  if (projectListener) {
    projectListener();
    projectListener = null;
  }
};
