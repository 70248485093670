import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: boolean = false;

const editorOpenSlice = createSlice({
  initialState,
  name: "editorOpen",
  reducers: {
    setEditorOpen: (state: boolean, action: PayloadAction<boolean>) => {
      return action.payload;
    },
  },
});

export const { setEditorOpen } = editorOpenSlice.actions;

export default editorOpenSlice.reducer;
