import "react-modal-video/css/modal-video.css";

import "@core/components/ripple-button";

// import "@core/scss/core.scss";
import "@core/scss/base/plugins/extensions/ext-component-sweet-alerts.scss";

// import "nouislider/dist/nouislider.css";
import "@core/scss/react/libs/toastify/toastify.scss";

import React from "react";

import { ToastContainer } from "react-toastify";

import AppStart from "./App.authCheck";

import Routing from "./App.routing";
import AppUpdating from "./App.updating";

import AppFirstTime from "./routing/App.firstTime";

// import Counter from "./App.counter";

import styled from "styled-components";
import { Button } from "reactstrap";
//import TranslationService from "../@translations/TranslationService";
import Theming from "./App.theming";

import AppAuthStateHandling from "./App.authStateHanding";

import GlobalLoader from "./App.globalLoader";

import { ScreenDiv } from "./App.maincss";
// import HomeDialog from "home/home.dialog";
import AppStatisitics from "./App.statistics";
// import { setShowMain } from "redux/reducers/reducer.showMain";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers/_index";
import "./App.css";

export let platform: any = "web";

//@ts-ignore

//@ts-ignore

//@ts-ignore
export const ButtonRipple = styled(Button.Ripple)`
  &&&:active {
    background-color: initial;
  }
  &&&:focus {
    background-color: initial;
  }
  &&&.btn-primary:focus {
    background-color: var(--toolbar-background) !important;
  }
`;

export const IosHeaderDiv = styled.div`
  width: 100%;
  height: env(safe-area-inset-top);
  z-index: 50000;
`;

try {
  // window.screen.orientation.lock("portrait");
} catch (e) {}
export const isTouch = matchMedia("(hover: none)").matches;

// StatusBar.setStyle({ style: Style.Dark });

function App() {
  const showMain = useSelector<RootState, any>((state) => state.showMain);

  

  return (
    <React.Fragment>
      <ScreenDiv className={showMain ? "visib" : ""}>
        {/* <IosHeaderDiv className="smoothcover" /> */}
        <AppAuthStateHandling />
        <Theming />
        {/* <TranslationService /> */}
        {/* <UpgradeChecker /> */}
        {/* <AppUrlListener /> */}
        <Routing />
        <AppFirstTime />
        <AppStart />
        <AppStatisitics />
        {/* <SendIntentSetter /> */}
        <AppUpdating />
        <GlobalLoader />
        <ToastContainer
          newestOnTop
          enableMultiContainer
          containerId={"default"}
        />
      </ScreenDiv>
      {/* <HomeDialog /> */}
    </React.Fragment>
  );
}

export default App;
