import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const translationSlice = createSlice({
  initialState: {} as any,
  name: "activetranslations",
  reducers: {
    setActiveTranslation: (state: any, action: PayloadAction<any>) => {
      return action.payload;
    },
    setActiveTranslationValue: (state: any, action: PayloadAction<any>) => {
      state[action.payload.id] = action.payload.value;
    },
  },
});

export const { setActiveTranslation, setActiveTranslationValue } =
  translationSlice.actions;

export default translationSlice.reducer;
