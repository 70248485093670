import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: string = "";

const transLangSlice = createSlice({
  initialState,
  name: "transLang",
  reducers: {
    setTransLang: (state: any, action: PayloadAction<any>) => {
      return action.payload;
    },
  },
});

export const { setTransLang } = transLangSlice.actions;

export default transLangSlice.reducer;
