import styled from "styled-components";

export const ScreenDiv = styled.div`
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  display: flex;
  flex-direction: column;
  opacity: 0;
  transition: all 0.3s;
  &.visib {
    opacity: 1;
  }
`;

export const StickyHeader = styled.div`
  padding: 8px 10px 8px 10px;
  width: 100%;
  height: 50px;
  color: var(--toolbar-text);
  background: var(--toolbar-background);
  border-bottom: 1px solid var(--toolbar-background);
  font-size: 18px;
  transition: all 0.3s;
  display: flex;
  user-select: none;
  font-weight: bold;
  align-items: center;
  z-index: 1200;
`;

export const MainDiv = styled.div`
  flex: 1 1 auto;
  opacity: 0;
  &&.visib {
    opacity 1
  }
  transition: all 0.15s;
`;

export const AppDiv = styled.div`
  background: white;
  position: relative;
  height: 100%;
  width: 100%;
  overflow: auto;
  transition: all 0.15s;

  @media (min-width: 800px) {
    width: 800px;
    margin-left: calc(50% - 400px);
    margin-right: auto;
    margin-top: 15px;
    height: calc(100% - 80px);
    overflow: auto;
  }
`;

export const HideDiv = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  opacity: 0;
  &&.visib {
    opacity: 1;
  }
`;

export const ItemWrapperDiv = styled.div`
  padding: 20px;
`;
