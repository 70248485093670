import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const statisticsSlice = createSlice({
  initialState: {} as any,
  name: "statistics",
  reducers: {
    setStatistics: (state: any, action: PayloadAction<any>) => {
      return action.payload;
    },
  },
});

export const { setStatistics } = statisticsSlice.actions;

export default statisticsSlice.reducer;
