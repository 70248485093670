import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const projectsSlice = createSlice({
  initialState: {} as any,
  name: "activeprojects",
  reducers: {
    setActiveProject: (state: any, action: PayloadAction<any>) => {
      return action.payload;
    },
    setActiveProjectValue: (state: any, action: PayloadAction<any>) => {
      state[action.payload.id] = action.payload.value;
    },
  },
});

export const { setActiveProject, setActiveProjectValue } =
  projectsSlice.actions;

export default projectsSlice.reducer;
