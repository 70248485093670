import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: any = {};

const selectionSlice = createSlice({
  initialState,
  name: "selection",
  reducers: {
    setSelection: (state: any, action: PayloadAction<any>) => {
      return action.payload;
    },
    setSelectionItem: (state: any, action: PayloadAction<any>) => {
      state[action.payload.id] = action.payload.value;
    },
  },
});

export const { setSelection, setSelectionItem } = selectionSlice.actions;

export default selectionSlice.reducer;
