import React from "react";
import styled from "styled-components";

const WrapperDiv = styled.div`
  margin-top: 0px;
  padding: 15px;
  padding-top: 0px;
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 45px;
  background: white;
`;

const TextDiv = styled.div`
  font-size: 18px;
  font-family: var(--font-normal);
  margin-bottom: 10px;
  line-height: 130%;
`;

const TextIndentDiv = styled.div`
  font-size: 18px;
  font-family: var(--font-normal);
  margin-bottom: 20px;
  margin-left: 10px;
  line-height: 130%;
`;

const TextIndent1Div = styled.div`
  font-size: 18px;
  font-family: var(--font-normal);
  margin-bottom: 20px;
  margin-left: 30px;
  line-height: 130%;
`;

const MainHeaderDiv = styled.div`
  font-size: 26px;
  font-family: var(--font-normal);
  font-weight: 700;
  margin-top: 25px;
  margin-bottom: 15px;
  line-height: 1.3;
  color: var(--toolbar-background);
`;
const HeaderDiv = styled.div`
  font-size: 24px;
  font-family: var(--font-normal);
  font-weight: 700;
  margin-top: 25px;
  margin-bottom: 15px;
  line-height: 1.3;
  color: var(--toolbar-background);
`;

const Header1Div = styled.div`
  font-size: 21px;
  font-family: var(--font-normal);
  font-weight: 700;
  margin-top: 25px;
  margin-bottom: 15px;
  line-height: 1.3;
  color: var(--toolbar-background);
`;

const Header2Div = styled.div`
  font-size: 18px;
  font-family: var(--font-normal);
  font-weight: 700;
  margin-left: 10px;
  margin-top: 25px;
  margin-bottom: 15px;
  line-height: 1.3;
  color: var(--toolbar-background);
`;

const Header3Div = styled.div`
  font-size: 18px;
  font-family: var(--font-normal);
  font-weight: 700;
  margin-top: 25px;
  margin-bottom: 15px;
  line-height: 1.3;
  color: var(--toolbar-background);
`;

const TextIndentFirstDiv = styled.div`
  margin-left: 20px;
  margin-bottom: 10px;
  margin-top: 15px;
`;

const Privacy = (props: any) => {
  const { tt } = props;

  return (
    <React.Fragment>
      <WrapperDiv>
        <MainHeaderDiv>{tt("z1a", "Privacy Policy")}</MainHeaderDiv>
        <TextDiv>
          {tt(
            "uh1",
            `This privacy policy (“Policy”) describes how NetEnglish Solutions OÜ Hungarian Permanent Establishment („Service Provider”, “we”, “us” or “our”) collects, protects and uses the personally identifiable information (“Personal Information”) you (“User”, “you” or “your”) may provide on the inilingo.com website and any of its products or services (collectively, “Website” or “Services”).`
          )}
        </TextDiv>
        <TextDiv>
          {tt(
            "uh2",
            `This privacy policy also describes the choices available to you regarding our use of
          your Personal Information and how you can access and update this
          information.`
          )}{" "}
          {tt(
            "uh3",
            `This Policy does not apply to the practices of companies
          that we do not own or control, or to individuals that we do not employ
          or manage.`
          )}
        </TextDiv>
        <HeaderDiv>
          {tt("uh4", `Automatic collection of information; Profiling`)}
        </HeaderDiv>
        <TextDiv>
          {tt(
            "uh5",
            `Our top priority is customer data security and, as such, we exercise
          the no logs policy.`
          )}{" "}
          {tt(
            "uh6",
            `We may process only minimal user data, only as
          much as it is absolutely necessary to maintain the Website or
          Services.`
          )}{" "}
          {tt(
            "uh7",
            `Information collected automatically is used only to identify
          potential cases of abuse and establish statistical information
          regarding Website usage.`
          )}{" "}
          {tt(
            "uh8",
            `This statistical information is not otherwise
          aggregated in such a way that would identify any particular user of
          the system.`
          )}
        </TextDiv>
        <TextDiv>
          {tt(
            "stuh10",
            `Profiling is any form of automated processing of personal data in which personal data is used to assess, analyze or predict certain personal characteristics (such as interests, personal preferences) of a natural person.`
          )}{" "}
          {tt(
            "stuh9",
            `Please note that the data controller does not use decision-making based solely on automated data management, including profiling.`
          )}
        </TextDiv>
        <TextDiv>
          {tt(
            "stuh8",
            `Should the controller introduce a decision-making procedure based on such data management in the future, he will inform the data subject in advance by electronic means of the logic, method and substance used and give the data subject the opportunity to express his or her views or object to the decision.`
          )}
        </TextDiv>
        <HeaderDiv>{tt("uh9", `Collection of personal information`)}</HeaderDiv>
        <TextDiv>
          {tt(
            "uh10",
            `You can visit the Website without telling us who you are or revealing
          any information by which someone could identify you as a specific,
          identifiable individual.`
          )}{" "}
          {tt(
            "uh11",
            `If, however, you wish to use some of the
          Website's features, you may be asked to provide certain Personal
          Information (for example, your name and e-mail address).`
          )}{" "}
          {tt(
            "uh12",
            `We receive
          and store any information you knowingly provide to us when you create
          an account, publish content, make a purchase, or fill any online forms
          on the Website.`
          )}{" "}
          {tt(
            "uh13",
            `When required, this information may include the
          following:`
          )}
        </TextDiv>
        <TextIndentDiv>
          {tt(
            "uh14",
            `- Personal details such as name, country of residence, etc.`
          )}
        </TextIndentDiv>
        <TextIndentDiv>
          {tt(
            "uh15",
            `- Contact information such as email address, address, etc.`
          )}
        </TextIndentDiv>
        <TextIndentDiv>
          {tt(
            "uh16",
            `- Account details such as user name, unique user ID, password, etc.`
          )}
        </TextIndentDiv>
        <TextIndentDiv>
          {tt(
            "uh17",
            `- Payment information such as credit card details, bank details, etc.`
          )}
        </TextIndentDiv>
        <TextIndentDiv>
          {tt(
            "uh18",
            `- Any other materials you willingly submit to us such as articles,
          images, feedback, etc.`
          )}
        </TextIndentDiv>
        <TextDiv>
          {tt(
            "uh19",
            `You can choose not to provide us with your Personal Information, but
          then you may not be able to take advantage of some of the Website's
          features.`
          )}{" "}
          {tt(
            "uh20",
            `Users who are uncertain about what information is mandatory
          are welcome to contact us. (Detailed description of each particular
          use of data collected can be read later in this text.)`
          )}
        </TextDiv>
        <HeaderDiv>
          {tt("uh21", `Use and processing of collected information`)}
        </HeaderDiv>
        <TextDiv>
          {tt(
            "uh22",
            `In order to make our Website and Services available to you, or to meet
          a legal obligation, we need to collect and use certain Personal
          Information.`
          )}{" "}
          {tt(
            "uh23",
            `If you do not provide the information that we request, we
          may not be able to provide you with the requested products or
          services.`
          )}{" "}
          {tt(
            "uh24",
            `Some of the information we collect is directly from you via
          our Website.`
          )}{" "}
          {tt(
            "uh25",
            `However, we may also collect Personal Information about
          you from other sources.`
          )}{" "}
          {tt(
            "uh26",
            `Any of the information we collect from you may
          be used for the following purposes:`
          )}
        </TextDiv>
        <TextIndentFirstDiv>
          {tt("uh27", `- Create and manage user accounts`)}
        </TextIndentFirstDiv>
        <TextIndentDiv>
          {tt("uh28", `- Fulfill and manage orders`)}
        </TextIndentDiv>
        <TextIndentDiv>
          {tt("uh29", `- Deliver products or services`)}
        </TextIndentDiv>
        <TextIndentDiv>
          {tt("uh30", `- Improve products and services`)}
        </TextIndentDiv>
        <TextIndentDiv>
          {tt("uh31", `- Send administrative information`)}
        </TextIndentDiv>
        <TextIndentDiv>
          {tt("uh32", `- Send marketing and promotional communications`)}
        </TextIndentDiv>
        <TextIndentDiv>
          {tt("uh33", `- Respond to inquiries and offer support`)}
        </TextIndentDiv>
        <TextIndentDiv>{tt("uh34", `- Request user feedback`)}</TextIndentDiv>
        <TextIndentDiv>{tt("uh35", `- Improve user experience`)}</TextIndentDiv>
        <TextIndentDiv>
          {tt("uh36", `- Post customer testimonials`)}
        </TextIndentDiv>
        <TextIndentDiv>
          {tt("uh37", `- Deliver targeted advertising`)}
        </TextIndentDiv>
        <TextIndentDiv>
          {tt("uh38", `- Administer prize draws and competitions`)}
        </TextIndentDiv>
        <TextIndentDiv>
          {tt("uh39", `- Enforce terms and conditions and policies`)}
        </TextIndentDiv>
        <TextIndentDiv>
          {tt("uh40", `- Protect from abuse and malicious users`)}
        </TextIndentDiv>
        <TextIndentDiv>
          {tt("uh41", `- Respond to legal requests and prevent harm`)}
        </TextIndentDiv>
        <TextIndentDiv>
          {tt("uh42", `- Run and operate our Website and Services`)}
        </TextIndentDiv>
        <TextDiv>
          {tt(
            "uh43",
            `Processing your Personal Information depends on how you interact with
          our Website, where you are located in the world and if one of the
          following applies:`
          )}{" "}
          {tt(
            "uh44",
            `(i) you have given your consent for one or more
          specific purposes; this, however, does not apply, whenever the
          processing of Personal Information is subject to California Consumer
          Privacy Act or European data protection law;`
          )}{" "}
          {tt(
            "uh45",
            `(ii) provision of
          information is necessary for the performance of an agreement with you
          and/or for any pre-contractual obligations thereof;`
          )}{" "}
          {tt(
            "uh46",
            `(iii) processing
          is necessary for compliance with a legal obligation to which you are
          subject;`
          )}{" "}
          {tt(
            "uh46gh",
            `(iv) processing is related to a task that is carried out in
          the public interest or in the exercise of official authority vested in
          us;`
          )}{" "}
          {tt(
            "uh47",
            `(v) processing is necessary for the purposes of the legitimate
          interests pursued by us or by a third party.`
          )}
        </TextDiv>
        <HeaderDiv>
          {tt(
            "uh48",
            `Description of personal data, the purpose, legal basis and duration of
          data processing`
          )}
        </HeaderDiv>
        <TextDiv>
          {tt(
            "uh49",
            `Processing of data relating to the users of the IniLingo web service and software products
          shall be made on the basis of the users’ freely given and informed
          consent, which shall contain the Data Subject’s explicit consent for
          the processing of personal data provided in the course of using the
          Website or our Software products.`
          )}{" "}
          {tt(
            "uh50",
            `The processing of data by the Data Controller shall be made
          on the basis of the Data Subject’s voluntary consent pursuant to
          Section 5(1) Paragraph a) of Act CXII of 2011 on the right of
          informational self-determination and on freedom of information
          (hereinafter referred to as: Freedom of Information Act) and on the
          basis of Act CVIII of 2001 on certain issues of electronic commerce
          and information society services.`
          )}{" "}
          {tt(
            "uh51",
            `The Service Provider does not verify
          the authenticity of the personal data received.`
          )}{" "}
          {tt(
            "uh52",
            `The provider of the
          personal data, the data subject or contracting party shall bear the
          exclusive responsibility for the accuracy of the data provided.`
          )}{" "}
          {tt(
            "uh53",
            `The
          Data Subject undertakes to be the sole person using the services with
          the data provided.`
          )}{" "}
          {tt(
            "uh54",
            `With respect to this, the Data Subject who
          registered the e-mail address shall bear all responsibility in
          relation to signing in with the data provided.`
          )}{" "}
          {tt(
            "uh55",
            `The processing of data
          by the Service Provider is based on the voluntarily given consent of
          the Data Subject, of which the Service Provider shall provide
          information in this paragraph, and on separate, incidental occasions.`
          )}
        </TextDiv>
        <HeaderDiv>
          {tt("uh57", `Specific procedures and uses of data`)}
        </HeaderDiv>
        <TextDiv>
          {tt(
            "uh58",
            `Service Provider acts as data controller cooperating with
          various data processors to provide services to users.`
          )}
        </TextDiv>
        <Header1Div>{tt("uh59", `1. Data controller`)}</Header1Div>
        <TextIndentDiv>
          {tt(
            "uh60",
            `*Name:* NetEnglish Solutions OÜ Hungarian Permanent Establishment`
          )}
        </TextIndentDiv>
        <TextIndentDiv>
          {tt(
            "uh61",
            `*Headquarters:* 9700 Szombathely, Deák Ferenc utca 29. Hungary`
          )}
        </TextIndentDiv>
        <TextIndentDiv>
          {tt("uh62", `*Tax number:* 27972984-2-18`)}
        </TextIndentDiv>
        <TextIndentDiv>
          {tt("uh63", `*Company registration number:* 18-17-000025`)}
        </TextIndentDiv>
        <TextIndentDiv>
          {tt("uh64", `*Website:* https://netenglish.com`)}
        </TextIndentDiv>
        <TextIndentDiv>
          {tt("uh65", `*E-mail:* support@netenglish.com`)}
        </TextIndentDiv>
        <TextIndentDiv>
          {tt(
            "uh66",
            `*Person responsible for data control:* Peter Szemán (managing
          director)`
          )}
        </TextIndentDiv>
        <TextIndentDiv>
          {tt(
            "uh67",
            `*Representatives:* Peter Szeman managing director, independently,
          Ildikó Németh managing director, independently`
          )}
        </TextIndentDiv>
        <Header1Div>{tt("uh68", `2. Data processors`)}</Header1Div>
        <Header3Div>{tt("uh69", `Google LLC`)}</Header3Div>
        <TextIndentDiv>
          {tt(
            "uh70",
            `*Address:* 1600 Amphitheatre Parkway Mountain View, CA 94043`
          )}
        </TextIndentDiv>
        <TextIndentDiv>
          {tt("uh71", `*Webpage:* https://google.com`)}
        </TextIndentDiv>
        <TextIndentDiv>
          {tt(
            "uh72",
            `*Activity:* Analitical and remarketing services, hosting, data
          processing and storage`
          )}
        </TextIndentDiv>
        <TextIndentDiv>
          {tt("uh73", `*Details and procedure of personal data handling:*`)}
        </TextIndentDiv>
        <Header2Div>{tt("uh74", `Google Analytics:`)}</Header2Div>
        <TextIndentDiv>
          {tt(
            "uh75",
            `The Data Processor uses Google Analytics primarily to collect
          statistical data to analyze the success of its marketing activities.`
          )}{" "}
          {tt(
            "uh76",
            `It mainly measures how many visitors accessed the pages and how much
          time they spent there.`
          )}{" "}
          {tt(
            "uh77",
            `The program records the visitor's IP address
          and like this it is able to follow whether the visitor is a returning
          visitor, and what pages they visited on the web site.`
          )}{" "}
          {tt(
            "uh78",
            `It also receives
          information about the amount paid and the item paid when the User purchases something without any personal data about the person.`
          )}{" "}
          {tt(
            "uh79",
            `This activity (with anonimized data) is
          transferred to Google LLC servers.`
          )}{" "}
          {tt(
            "uh80cx",
            `This data processing can be opted
          out of individually by denying the use of marketing cookies in the
          cookie settings.`
          )}
        </TextIndentDiv>
        <Header2Div>{tt("uh80werwer ", `Google Remarketing:`)}</Header2Div>
        <TextIndentDiv>
          {tt(
            "uh81",
            `The data controller uses a different type of cookie beside Google
          Analytics (called DoubleClick) to collect data about the sites the
          User visits.`
          )}{" "}
          {tt(
            "uh82",
            `Google then uses anonimized data to show ads relevant to
          the Service Provider in ads on other websites where you allow the
          use of such cookies.`
          )}{" "}
          {tt(
            "uh83",
            `You can opt out of this specifically in the
          cookie settings.`
          )}{" "}
          {tt(
            "uh84",
            `The following data are communicated to Google LLC
          unless you opt out:`
          )}{" "}
          {tt(
            "uh85",
            `The User's IP address, browser data, the content
          of the cookie set, data about individual purchases, specifically the
          price paid and the item bought (no name or other personal data are
          transferred).`
          )}
        </TextIndentDiv>
        <Header2Div>{tt("uh86", `Google Firebase database:`)}</Header2Div>
        <TextIndentDiv>
          {tt(
            "uh87",
            `Service Provider uses Google Firebase and Google Firebase
          Authentication to store and handle most of the data required to handle
          signed up users and customers.`
          )}{" "}
          {tt(
            "uh88",
            `Data stored in Google Firebase are NOT
          used by Google LLC for marketing purposes.`
          )}{" "}
          {tt(
            "uh89",
            `Google Firebase receives
          the following data during our data processing:`
          )}
        </TextIndentDiv>
        <Header2Div>
          {tt("ccuh86", `Google Cloud Storage and Firestore:`)}
        </Header2Div>

        <TextIndentDiv>
          {tt(
            "ccuh87",

            `Service Provider uses Google Cloud storage and Firestore services to store images, sound recordings and files of users intended to be recorded as part of your notes and papers. All notes are similarly stored on Google Cloud Storage and in Firestore.`
          )}{" "}
          {tt(
            "ccuh88",

            `These files are stored in a location only accessible by Service Provider, the concerned user and any users the actual owner of the information specifically shares the data with.`
          )}{" "}
        </TextIndentDiv>
        <Header2Div>{tt("uh90", `Signing up for an account:`)}</Header2Div>
        <TextIndentDiv>
          {tt(
            "uh91",
            `Google receives and stores the User's first name, language choice,
          hashed password and email address identifying a User and making it
          possible to use the members only services of Service Provider.`
          )}{" "}
          {tt(
            "uh92",
            `These are
          used to allow logging in and serving of content in the User's
          language.`
          )}
        </TextIndentDiv>
        <Header2Div>{tt("uh93", `Using one's account:`)}</Header2Div>
        <TextIndentDiv>
          {tt(
            "uh94",
            `Google Firebase receives data about the activity of the User while opening and handling notes.`
          )}{" "}
          {tt("uh95", `It specifically receives the following data:`)}
        </TextIndentDiv>
        <TextIndent1Div>
          {tt(
            "uh96ccc1",
            `What Notes were opened and when they were last edited.`
          )}
        </TextIndent1Div>

        <TextIndentDiv>
          {tt("uh99", `The basis of sending this data is your agreement.`)}{" "}
          {tt(
            "uh100",
            `These pieces of data are only used to show you your recent activity and to synchronize your activity between your various devices.`
          )}{" "}
          {tt(
            "uh101",
            `Such data are never shared
          with third parties without your explicit approval.`
          )}
        </TextIndentDiv>
        <Header2Div>
          {tt("uh102", `Buying or subscribing to services:`)}
        </Header2Div>
        <TextIndentDiv>
          {tt(
            "uh103",
            `Google receives the following data: Full name, zip code, country,
          address, specifics of what was bought and for what price.`
          )}{" "}
          {tt(
            "uh104",
            `Google also
          receives the anonimized data of the financial transaction which is
          needed for us to be able to process your payments and deliver services
          to you.`
          )}
        </TextIndentDiv>
        <TextIndentDiv>
          {tt(
            "uh105",
            `Data stored in Google Firebase are used only for delivering our
          Services.`
          )}{" "}
          {tt(
            "uh106",
            `Google doesn't have access to them for marketing purposes.`
          )}
        </TextIndentDiv>
        <TextIndentDiv>
          {tt(
            "uh108",
            `All the above data are stored primarily on the secure servers
          of Google LLC located in the United States of America.`
          )}{" "}
          {tt(
            "uh107",
            `Google has its own privacy policy available on its
          website on the following link: https://policies.google.com/privacy?hl=en&fg=1.`
          )}{" "}
        </TextIndentDiv>
        <Header3Div>{tt("uh109", `Facebook, Inc`)}</Header3Div>
        <TextIndentDiv>
          {tt("uh109a", `*Address:* 1601 Willow Road Menlo Park, CA 94025`)}
        </TextIndentDiv>
        <TextIndentDiv>
          {tt("uh110", `*Web page:* https://facebook.com`)}
        </TextIndentDiv>
        <TextIndentDiv>
          {tt("uh111", `*Activity:* analytic and remarketing`)}
        </TextIndentDiv>
        <TextIndentDiv>
          {tt("uh112", `*Data shared and how it is used:*`)}
        </TextIndentDiv>
        <TextIndentDiv>
          {tt(
            "uh113",
            `The data controller uses Facebook's remarketing pixels to improve the
          efficiency of its Facebook ads and to build a so called remarketing
          list.`
          )}{" "}
          {tt(
            "uh114",
            `The data shared with Facebook only contain data about the User's
          web browser and cannot be used to identify the User.`
          )}{" "}
          {tt(
            "uh115",
            `In case of buying
          something or signing up for an account Facebook also receives the
          price of the purchase and the item purchased (without the User's
          personal data).`
          )}{" "}
          {tt(
            "uh116",
            `These data are used by Facebook to show more relevant
          ads to the person and to show more ads from the Service Provider in the
          future to the User.`
          )}{" "}
          {tt(
            "uh117",
            `This data processing can be opted out of
          individually by denying the use of marketing cookies in the cookie
          settings.`
          )}
        </TextIndentDiv>
        <TextDiv>
          {tt(
            "lzsld23",
            `Facebook has its own Privacy Policy available at: https://en-us.facebook.com/privacy/explanation`
          )}
        </TextDiv>
        {/* <Header3Div>{tt("uh118", `KBOSS.hu Kft.`)}</Header3Div>
        <TextIndentDiv>
          {tt("uh119", `*Web page:* https://www.szamlazz.hu/`)}
        </TextIndentDiv>
        <TextIndentDiv>
          {tt("uh120", `*Tax number:* 13421739-2-41`)}
        </TextIndentDiv>
        <TextIndentDiv>
          {tt("uh121", `*Registry no.:* 01-09-303201`)}
        </TextIndentDiv>
        <TextIndentDiv>
          {tt("uh122", `*Address:* 1031 Budapest, Záhony utca 7/c.`)}
        </TextIndentDiv>
        <TextIndentDiv>
          {tt("uh123", `*E-mail:* info@szamlazz.hu`)}
        </TextIndentDiv>
        <TextIndentDiv>
          {tt(
            "uh124",
            `*Activity:* Creating and storing invoices of purchases.`
          )}
        </TextIndentDiv>
        <TextIndentDiv>
          {tt(
            "uh125",
            `*User's data shared:* User ID, full name, company name, address, email
          address, details of payments (items, tax content, price, discounts
          applied)`
          )}
        </TextIndentDiv>
        <TextIndentDiv>
          {tt(
            "uh126",
            `The basis here is legal requirements for the Service Provider.`
          )}
        </TextIndentDiv>
        <TextDiv>
          {tt(
            "lzsld2312",
            `KBOSS.hu Kft. has its own Privacy Policy available at: https://www.szamlazz.hu/adatvedelem/`
          )}
        </TextDiv> */}
        <Header3Div>{tt("uh127", `Twilio Ireland Limited`)}</Header3Div>
        <TextIndentDiv>{tt("uh128", `*Web page:* sendgrid.com`)}</TextIndentDiv>
        <TextIndentDiv>
          {tt("uh129", `*Address:* 25-28 North Wall Quay, Dublin 1, Ireland`)}
        </TextIndentDiv>
        <TextIndentDiv>
          {tt(
            "uh130",
            `*Activity:* sending required emails connected to the use of the
          website and sending email newsletters`
          )}
        </TextIndentDiv>
        <TextIndentDiv>
          {tt(
            "uh131",
            `*User's data shared:* First name, email address, subscription status,
          items bought.`
          )}
        </TextIndentDiv>
        <TextIndentDiv>
          {tt("uh132", `The basis of the data processing is your agreement.`)}
        </TextIndentDiv>
        <Header2Div>{tt("uh133", `Important messages`)}</Header2Div>
        <TextIndentDiv>
          {tt(
            "uh134",
            `Service Provider uses Twilio Sendgrid as its primary means of
          sending e-mail messages necessary for the use of our Services.`
          )}{" "}
          {tt(
            "uh135",
            `Such
          use include messages regarding your account (password change, billing,
          informing you of changes to our policies etc.)`
          )}{" "}
          {tt(
            "uh136",
            `This use is continuous
          until you personally decide to revoke your agreement for such or ask
          us to delete your data.`
          )}
        </TextIndentDiv>
        <Header2Div>{tt("uh137", `Newsletters`)}</Header2Div>
        <TextIndentDiv>
          {tt(
            "uh138",
            `Service Provider uses Twilio Sendgrid to send out newsletters to
          people who specifically asked us to do so.`
          )}{" "}
          {tt(
            "uh140",
            `Our e-mails contain an
          Unsubscribe option which would remove you from our mailing list for
          further newsletters.`
          )}
        </TextIndentDiv>
        <TextIndentDiv>
          {tt(
            "uh141wer",
            `Twilio Sendgrid might use other tracking devices called “beacons” to
          monitor the success of its emails.`
          )}
        </TextIndentDiv>
        <TextDiv>
          {tt(
            "lzsld2318",
            `Twilio Sendgrid has its own Privacy Policy available at: https://www.twilio.com/legal/privacy`
          )}
        </TextDiv>

        <Header3Div>{tt("zfwwuh142", `Apple Inc.`)}</Header3Div>
        <TextIndentDiv>
          {tt(
            "zfwwuh143",
            `*Address:* One Apple Park Way; Cupertino, CA 95014, U.S.A.`
          )}
        </TextIndentDiv>
        <TextIndentDiv>
          {tt("zfwwuh144", `*Web page:* apple.com`)}
        </TextIndentDiv>
        <TextIndentDiv>
          {tt("zfwwuh145", `*Activity:* merchant of record`)}
        </TextIndentDiv>
        <TextIndentDiv>
          {tt(
            "zfwwuh146",
            `*User's data shared:* Type of subscription requested, price of
          subscription, item requested, price of item requested, user name, user email, user address.`
          )}
        </TextIndentDiv>
        <TextIndentDiv>
          {tt(
            "wzfwuh147",
            `Service Provider uses Apple Inc. to process payments on iPhones and iPads where users can use various payment methods.`
          )}{" "}
          {tt(
            "zfwwuh148",
            `Data entered in their programs are not visible to the Service Provider, these
          data are entered on their own website so their data
          protection policy applies there.`
          )}{" "}
          {tt(
            "zfwwuh151",
            `Service Provider receives back the approval or
          disapproval from Apple Inc. and stores such data because of legal
          obligation as a proof of the transaction.`
          )}{" "}
          {tt(
            "zfwwuh149",
            `If you are using IniLingo on Apple devices you already agreed to follow Apple's privacy policy regarding payments and use of their services.`
          )}{" "}
        </TextIndentDiv>
        <TextDiv>
          {tt(
            "zflzsld231191",
            `Apple Inc. has its own Privacy Policy available at: https://www.apple.com/legal/privacy/`
          )}
        </TextDiv>

        <Header3Div>{tt("azfwwuh142", `Google Ireland Limited`)}</Header3Div>
        <TextIndentDiv>
          {tt(
            "azfwwuh143",
            `*Address:* Gordon House, Barrow Street, Dublin 4, Ireland`
          )}
        </TextIndentDiv>
        <TextIndentDiv>
          {tt("azfwwuh144", `*Web page:* google.com`)}
        </TextIndentDiv>
        <TextIndentDiv>
          {tt("azfwwuh145", `*Activity:* here merchant of record`)}
        </TextIndentDiv>
        <TextIndentDiv>
          {tt(
            "azfwwuh146",
            `*User's data shared:* Type of subscription requested, price of
          subscription, item requested, price of item requested, user name, user email, user address.`
          )}
        </TextIndentDiv>
        <TextIndentDiv>
          {tt(
            "awzfwuh147",
            `Service Provider uses Google Ireland Limited to process payments on Android devices where users can use various payment methods.`
          )}{" "}
          {tt(
            "azfwwuh148",
            `Data entered in their programs are not visible to the Service Provider, these
          data are entered on their own website so their data
          protection policy applies there.`
          )}{" "}
          {tt(
            "azfwwuh151",
            `Service Provider receives back the approval or
          disapproval from Google Ireland Limited and stores such data because of legal
          obligation as a proof of the transaction.`
          )}{" "}
          {tt(
            "azfwwuh149",
            `If you are using IniLingo on Android devices you already agreed to follow Google Ireland Limited's privacy policy regarding payments and use of their services.`
          )}{" "}
        </TextIndentDiv>
        <TextDiv>
          {tt(
            "azflzsld231191",
            `Google Ireland Limited has its own Privacy Policy available at: https://policies.google.com/privacy`
          )}
        </TextDiv>

        <Header3Div>{tt("wwuh142", `Paddle.com Market Limited`)}</Header3Div>
        <TextIndentDiv>
          {tt(
            "wwuh143",
            `*Address:* Judd House, 18-29 Mora Street, London, EC1V 8BT, United Kingdom`
          )}
        </TextIndentDiv>
        <TextIndentDiv>{tt("wwuh144", `*Web page:* paddle.com`)}</TextIndentDiv>
        <TextIndentDiv>
          {tt("wwuh145", `*Activity:* merchant of record`)}
        </TextIndentDiv>
        <TextIndentDiv>
          {tt(
            "wwuh146",
            `*User's data shared:* Type of subscription requested, price of
          subscription, item requested, price of item requested, user name, user email, user address.`
          )}
        </TextIndentDiv>
        <TextIndentDiv>
          {tt(
            "wwuh147",
            `Service Provider uses Paddle.com Market Limited (among other firms) to process debit and credit
          card payments and payments through various payment methods. They act as merchant of record for Service Provider.`
          )}{" "}
          {tt(
            "wwuh148",
            `Data entered on their website are not visible to the Service Provider, these
          data are entered on their own website so their data
          protection policy applies there.`
          )}{" "}
          {tt(
            "wwuh149",
            `Paddle.com Market Limited will ask only your card
          data or PayPal credentials to process the payment or approve a subscription.`
          )}{" "}
          {tt(
            "wwuh150",
            `Those data are
          securely stored on their secure equipment.`
          )}{" "}
          {tt(
            "wwuh151",
            `Service Provider receives back the approval or
          disapproval from Paddle.com and stores such data because of legal
          obligation as a proof of the transaction.`
          )}{" "}
          {tt(
            "wwuh152",
            `These data include the last
          4 digits of your card and the expiration date.`
          )}
        </TextIndentDiv>
        <TextDiv>
          {tt(
            "lzsld231191",
            `Paddle.com Market Limited has its own Privacy Policy available at: https://www.paddle.com/legal/privacy`
          )}
        </TextDiv>
        <Header3Div>{tt("uh153", `PayPal`)}</Header3Div>
        <TextIndentDiv>
          {tt("uh154", `*Name:* PayPal (Europe) S.à.rl et Cie, SCA`)}
        </TextIndentDiv>
        <TextIndentDiv>
          {tt("uh155", `*Address:* 22-24 Boulevard Royal L-2449, Luxembourg`)}
        </TextIndentDiv>
        <TextIndentDiv>
          {tt("uh156", `*Website:* https://paypal.com`)}
        </TextIndentDiv>
        <TextIndentDiv>
          {tt("uh157", `*Activity:* Payment gateway`)}
        </TextIndentDiv>
        <TextIndentDiv>
          {tt(
            "uh158",
            `*User's data shared:* Type of subscription requested, price of
          subscription, user name, user email, user address.`
          )}
        </TextIndentDiv>
        <TextIndentDiv>
          {tt(
            "uh159",
            `When you decide to pay with PayPal you are forwarded to PayPal's
          website where you can do your payment.`
          )}{" "}
          {tt(
            "uh160",
            `All activities while dealing
          with PayPal and all data shared by you on their website are covered by
          their Privacy Policy.`
          )}
        </TextIndentDiv>
        <TextIndentDiv>
          {tt(
            "uh161",
            `We receive back from PayPal a message about the success or rejection
          of your payment including the last 4 digits of your card and its
          expiration date with some technical info (not containing personal
          data) regarding the payment.`
          )}{" "}
          {tt(
            "uh162",
            `These are stored by Service Provider for legal and tax purposes as a proof of a successful or
          unsuccessful purchase.`
          )}
        </TextIndentDiv>
        <TextDiv>
          {tt(
            "lzsld2311934",
            `PayPal has its own Privacy Policy available at: https://www.paypal.com/us/webapps/mpp/ua/privacy-full`
          )}
        </TextDiv>
        <HeaderDiv>{tt("uh163", `When and how we use your data?`)}</HeaderDiv>
        <Header1Div>{tt("uh164", `A. IniLingo website`)}</Header1Div>
        <TextIndentDiv>
          {tt("uh165", `*The legal basis of data processing:*`)}{" "}
          {tt(
            "uh166",
            `the Data Subject’s voluntary
          consent pursuant to Section 5(1) Paragraph b) of the Hungarian Act CXII of 2010 on the right to information self-determination and Freedom of
          Information, and section 13/A of Act CVIII of 2001 on certain
          issues of electronic commerce and information society services.`
          )}
        </TextIndentDiv>
        <TextIndentDiv>
          {tt("uh167", `*Description of processed data:*`)}{" "}
          {tt(
            "uh168",
            `The computer identifier (IP address)
          of the Data Subject’s computer, the browser type, the date and time of
          visit, the content accessed, the website from where the visitor came
          from, and the site which the visitor accesses after exiting the
          website.`
          )}{" "}
          {tt(
            "uh169",
            `These data are unsuitable for the personal identification of
          the Data Subject, and the Data Controller, except for when it is
          provided by law and with the observance of conditions laid down
          therein, shall not take steps to identify the owner of the IP address.`
          )}
        </TextIndentDiv>
        <TextIndentDiv>
          {tt(
            "uh170",
            `*The purpose of data processing:* statistical data analysis,
          fulfillment of the Service, verification of the functioning of the
          Service, improvement of user experience, customisation of the Service.`
          )}{" "}
          {tt(
            "uh171",
            `If the User approved the use of marketing cookies, data is forwarded to
          other data processors as described earlier.`
          )}
        </TextIndentDiv>
        <TextIndentDiv>
          {tt("uh172", `*The duration of data processing:*`)}{" "}
          {tt("uh173", `1800 days starting from accessing the website.`)}{" "}
          {tt(
            "uh174",
            `The html code of the Data Controller’s
          website available at inilingo.com contains links from and to
          third-party servers, which are independent of the Data Controller.`
          )}{" "}
          {tt(
            "uh175",
            `The
          providers of these links are able to collect data upon direct
          connection to their servers.`
          )}
        </TextIndentDiv>
        <Header1Div>
          {tt("uh176", `B. Signing up for an IniLingo account`)}
        </Header1Div>
        <TextIndentDiv>
          {tt("uh177", `*The legal basis of data processing:*`)}{" "}
          {tt(
            "uh178",
            `the Data Subject’s voluntary
          consent.`
          )}
        </TextIndentDiv>
        <TextIndentDiv>
          {tt("uh179", `*Description of processed data:*`)}{" "}
          {tt(
            "uh180",
            `Selected langauge, first name, email
          address, password, acceptance or rejection of newsletters,
          anonimized data to make sure it is a valid sign-up not an automated
          act.`
          )}{" "}
          {tt(
            "uh181",
            `The user's browser sends these data in a secure way to our
          servers operated by Google LLC in the United States and stores these
          data as described above.`
          )}
        </TextIndentDiv>
        <TextIndentDiv>
          {tt("uh182", `*The purpose of data processing:*`)}{" "}
          {tt(
            "uh183",
            `Storing the identification details
          for secure access to our members only web services, getting the
          agreement of the person to receive newsletters.`
          )}
        </TextIndentDiv>
        <TextIndentDiv>
          {tt("uh184", `*The duration of data processing:*`)}{" "}
          {tt(
            "uh185",
            `Until the receipt of the Data
          Subject’s request for deletion sent to the Data Controller.`
          )}{" "}
          {tt(
            "uh186",
            `The Data
          Controller shall delete the data from the system within 5 working days
          following the receipt of the request.`
          )}
        </TextIndentDiv>
        <Header1Div>
          {tt(
            "uh187",
            `C. The use of the IniLingo web application and software products`
          )}
        </Header1Div>
        <TextIndentDiv>
          {tt("uh188", `*The legal basis of data processing:*`)}{" "}
          {tt(
            "uh189",
            `the Data Subject’s voluntary
          consent.`
          )}
        </TextIndentDiv>
        <TextIndentDiv>
          {tt("uh190", `*Description of processed data:*`)}{" "}
          {tt(
            "uh191",
            `The full name of the Data Subject,
          his e-mail address, location, gender, the progress and interactions of
          the Data Subject in connection with the Service described above and all contents of notes the user records.`
          )}
        </TextIndentDiv>
        <TextIndentDiv>
          {tt("uh192", `*The purpose of data processing:*`)}{" "}
          {tt(
            "uh193",
            `statistical data analysis,
          fulfillment of the Service, verification of the functioning of the
          Service, improvement of user experience, customisation of the Service,
          prevention of abuse.`
          )}
        </TextIndentDiv>
        <TextIndentDiv>
          {tt("uh194", `*The duration of data processing:*`)}{" "}
          {tt(
            "uh195",
            `Until the receipt of the Data
          Subject’s request for deletion sent to the Data Controller.`
          )}{" "}
          {tt(
            "uh196",
            `The Data
          Controller shall delete the data from the system within 5 working days
          following the receipt of the request.`
          )}
        </TextIndentDiv>
        <Header1Div>{tt("uh197", `D. Payment for subscription`)}</Header1Div>
        <TextIndentDiv>
          {tt("uh198", `*The legal basis of data processing:*`)}{" "}
          {tt(
            "uh199",
            `the Data Subject’s voluntary
          consent.`
          )}
        </TextIndentDiv>
        <TextIndentDiv>
          {tt("uh200", `*Description of processed data:*`)}{" "}
          {tt(
            "uh201",
            `The full name of the Data Subject,
          his e-mail address, address, company name, item bought, price for item
          bought, discounts applied.`
          )}
        </TextIndentDiv>
        <TextIndentDiv>
          {tt("uh202", `*The purpose of data processing:*`)}{" "}
          {tt(
            "uh203",
            `fulfillment of the Service,
          stastical data analysis.`
          )}
        </TextIndentDiv>
        <TextIndentDiv>
          {tt("uh204", `*Procedure:* As described above.`)}
        </TextIndentDiv>
        <TextIndentDiv>
          {tt("uh205", `*The duration of data processing:*`)}{" "}
          {tt(
            "uh206cx",
            `As required by law or, if not
          covered by law, until the receipt of the Data Subject’s request for
          deletion sent to the Data Controller.`
          )}{" "}
          {tt(
            "uh207cx",
            `The Data Controller shall delete
          the data from the system within 5 working days following the receipt
          of the request.`
          )}
        </TextIndentDiv>
        <Header1Div>{tt("uh206", `E. Newsletter`)}</Header1Div>
        <TextIndentDiv>
          {tt("uh207", `*The legal basis of data processing:*`)}{" "}
          {tt("uh208", `the User’s voluntary consent.`)}
        </TextIndentDiv>
        <TextIndentDiv>
          {tt("uh209", `*Description of processed data:*`)}{" "}
          {tt(
            "uh210",
            `User ID, full name of Data Subject,
          his e-mail address, the date and time of subscription, type of
          subscription.`
          )}
        </TextIndentDiv>
        <TextIndentDiv>
          {tt("uh211", `*The purpose of data processing:*`)}{" "}
          {tt(
            "uh212",
            `sending of newsletters and other
          advertisements, communication of current information, invitation of
          friends, sending of system messages and notifications regarding the
          use of the Service.`
          )}
        </TextIndentDiv>
        <TextIndentDiv>
          {tt("uh213", `*The duration of data processing:*`)}{" "}
          {tt(
            "uh214",
            `Until the unsubscription of the
          Data Subject.`
          )}{" "}
          {tt(
            "uh215",
            `For the analysis of the Data Subject’s interactions with
          the newsletter, the Service Provider uses newsletter tracking codes
          (Twilio Sendgrid), which provide feedback to the Service Provider if the
          Data Subject opened, read, or clicked on the newsletter.`
          )}{" "}
          {tt(
            "uh216",
            `The Data
          Subject is entitled, at any time and at no charge, to withdraw his
          consent by clicking on the “Unsubscribe” link located at the bottom of
          the newsletter.`
          )}{" "}
          {tt(
            "uh217",
            `After unsubscribing, the Data Subject will not receive
          any newsletters or other advertisements from the Data Controller, and
          the Data Controller shall delete the Data Subject’s data from the list
          of subscribed users.`
          )}
        </TextIndentDiv>
        <Header1Div>
          {tt("uh218", `F. Customer support, complaint-handling`)}
        </Header1Div>
        <TextIndentDiv>
          {tt("uh219", `*The legal basis of data processing:*`)}{" "}
          {tt("uh220", `the User’s voluntary consent.`)}
        </TextIndentDiv>
        <TextIndentDiv>
          {tt("uh221", `*Description of processed data:*`)}{" "}
          {tt(
            "uh222",
            `Name, e-mail address and other
          information provided voluntarily by the Data Subject.`
          )}
        </TextIndentDiv>
        <TextIndentDiv>
          {tt("uh223", `*The purpose of data processing:*`)}{" "}
          {tt(
            "uh224",
            `Recording the observations and
          questions of the Data Subject for the development and verification of
          the functioning of the Service, the handling of the Data Subject’s
          complaints.`
          )}{" "}
          {tt(
            "uh225asd",
            `The User originates an email to a designated e-mail
          address and the message and the email address will be recorded on
          our secure servers in a program called osTicket.`
          )}{" "}
          {tt(
            "uh225",
            `Our contracted
          customer service agents will read the data and potentially look up the
          User's existing subscription (name, address, type of subscription,
          subscription history, earlier problems or complaints) and will answer
          accordingly.`
          )}{" "}
          {tt(
            "uh227",
            `The User might answer to this and this sequence repeats
          until the problem is resolved.`
          )}{" "}
          {tt(
            "uh228",
            `The customer service agent might refer
          problems to other contracted custormer service agents for swift
          resolution.`
          )}
        </TextIndentDiv>
        <TextIndentDiv>
          {tt("uh229", `*The duration of data processing:*`)}{" "}
          {tt(
            "uh230",
            `up to 5 years commencing from the
          solving of the case.`
          )}
        </TextIndentDiv>
        <HeaderDiv>{tt("uh231", `Billing and payments`)}</HeaderDiv>
        <TextDiv>
          {tt(
            "uh232",
            `As mentioned above we use third party payment processors to assist us
          in processing your payment information securely.`
          )}{" "}
          {tt(
            "uh233",
            `Such third party
          processors' use of your Personal Information is governed by their
          respective privacy policies which may or may not contain privacy
          protections as protective as this Privacy Policy.`
          )}{" "}
          {tt(
            "uh235",
            `We suggest that you
          review their respective privacy policies.`
          )}
        </TextDiv>
        <HeaderDiv>{tt("uh236", `Managing information`)}</HeaderDiv>
        <TextDiv>
          {tt(
            "uh237",
            `You are able to delete certain Personal Information we have about you.`
          )}{" "}
          {tt(
            "uh238",
            `The Personal Information you can delete may change as the Website or
          Services change.`
          )}{" "}
          {tt(
            "uh239",
            `When you delete Personal Information, however, we may
          maintain a copy of the unrevised Personal Information in our records
          for the duration necessary to comply with our obligations to our
          affiliates and partners, and for the purposes described below.`
          )}{" "}
          {tt(
            "uh240",
            `If you
          would like to delete your Personal Information or permanently delete
          your account, you can do so by contacting us.`
          )}
        </TextDiv>
        <HeaderDiv>{tt("uh241", `Disclosure of information`)}</HeaderDiv>
        <TextDiv>
          {tt(
            "uh242",
            `Depending on the requested Services or as necessary to complete any
          transaction or provide any service you have requested, we may share
          your information with your consent with our trusted third parties that
          work with us, any other affiliates and subsidiaries we rely upon to
          assist in the operation of the Website and Services available to you.`
          )}{" "}
          {tt(
            "uh243",
            `A list of these and data shared with them can be found earlier in this policy.`
          )}{" "}
          {tt(
            "uh244",
            `We do not share Personal Information with unaffiliated third parties.`
          )}{" "}
          {tt(
            "uh245",
            `These Service Providers are not authorized to use or disclose your
          information except as necessary to perform services on our behalf or
          comply with legal requirements.`
          )}{" "}
          {tt(
            "uh246",
            `We may share your Personal Information
          for these purposes only with third parties whose privacy policies are
          consistent with ours or who agree to abide by our policies with
          respect to Personal Information.`
          )}{" "}
          {tt(
            "uh247",
            `These third parties are given
          Personal Information they need only in order to perform their
          designated functions, and we do not authorize them to use or disclose
          Personal Information for their own marketing or other purposes.`
          )}
        </TextDiv>
        <TextDiv>
          {tt(
            "uh248",
            `We will disclose any Personal Information we collect, use or receive
          if required or permitted by law, such as to comply with a subpoena, or
          similar legal process, and when we believe in good faith that
          disclosure is necessary to protect our rights, protect your safety or
          the safety of others, investigate fraud, or respond to a government
          request.`
          )}
        </TextDiv>
        <TextDiv>
          {tt(
            "uh249",
            `In the event we go through a business transition, such as a merger or
          acquisition by another company, or sale of all or a portion of its
          assets, your user account, and Personal Information will likely be
          among the assets transferred.`
          )}
        </TextDiv>
        <HeaderDiv>{tt("uh250", `Retention of information`)}</HeaderDiv>
        <TextDiv>
          {tt(
            "uh251",
            `We will retain and use your Personal Information for the period
          necessary to comply with our legal obligations, resolve disputes, and
          enforce our agreements unless a longer retention period is required or
          permitted by law.`
          )}{" "}
          {tt(
            "uh252",
            `We may use any aggregated data derived from or
          incorporating your Personal Information after you update or delete it,
          but not in a manner that would identify you personally.`
          )}{" "}
          {tt(
            "uh253",
            `Once the
          retention period expires, Personal Information shall be deleted.
          Therefore, the right to access, the right to erasure, the right to
          rectification and the right to data portability cannot be enforced
          after the expiration of the retention period.`
          )}
        </TextDiv>
        <HeaderDiv>{tt("uh254", `Transfer of information`)}</HeaderDiv>
        <TextDiv>
          {tt(
            "uh255",
            `Depending on your location, data transfers may involve transferring
          and storing your information in a country other than your own.`
          )}{" "}
          {tt(
            "uh256",
            `You are
          entitled to learn about the legal basis of information transfers to a
          country outside the European Union or to any international
          organization governed by public international law or set up by two or
          more countries, such as the UN, and about the security measures taken
          by us to safeguard your information.`
          )}{" "}
          {tt(
            "uh257",
            `If any such transfer takes place,
          you can find out more by checking the relevant sections of this
          Privacy Policy or inquire with us using the information provided in
          the contact section.`
          )}
        </TextDiv>
        <HeaderDiv>{tt("uh258", `The rights of users`)}</HeaderDiv>
        <TextDiv>
          {tt(
            "uh259",
            `You may exercise certain rights regarding your information processed
          by us.`
          )}{" "}
          {tt(
            "uh260",
            `In particular, you have the right to do the following:`
          )}{" "}
          {tt(
            "uh261",
            `(i) you
          have the right to withdraw consent where you have previously given
          your consent to the processing of your information;`
          )}{" "}
          {tt(
            "uh262",
            `(ii) you have the
          right to object to the processing of your information if the
          processing is carried out on a legal basis other than consent;`
          )}{" "}
          {tt(
            "uh263",
            `(iii)
          you have the right to learn if information is being processed by us,
          obtain disclosure regarding certain aspects of the processing and
          obtain a copy of the information undergoing processing;`
          )}{" "}
          {tt(
            "uh264",
            `(iv) you have
          the right to verify the accuracy of your information and ask for it to
          be updated or corrected;`
          )}{" "}
          {tt(
            "uh265",
            `(v) you have the right, under certain
          circumstances, to restrict the processing of your information, in
          which case, we will not process your information for any purpose other
          than storing it;`
          )}{" "}
          {tt(
            "uh266",
            `(vi) you have the right, under certain circumstances,
          to obtain the erasure of your Personal Information from us;`
          )}{" "}
          {tt(
            "uh267",
            `(vii) you
          have the right to receive your information in a structured, commonly
          used and machine readable format and, if technically feasible, to have
          it transmitted to another controller without any hindrance.`
          )}{" "}
          {tt(
            "uh268",
            `This
          provision is applicable provided that your information is processed by
          automated means and that the processing is based on your consent, on a
          contract which you are part of or on pre-contractual obligations
          thereof.`
          )}
        </TextDiv>
        <HeaderDiv>
          {tt("uh269cx", `The right to object to processing`)}
        </HeaderDiv>
        <TextDiv>
          {tt(
            "uh269",
            `Where Personal Information is processed for the public interest, in
          the exercise of an official authority vested in us or for the purposes
          of the legitimate interests pursued by us, you may object to such
          processing by providing a ground related to your particular situation
          to justify the objection.`
          )}{" "}
          {tt(
            "uh270",
            `You must know that, however, should your
          Personal Information be processed for direct marketing purposes, you
          can object to that processing at any time without providing any
          justification.`
          )}{" "}
          {tt(
            "uh271",
            `To learn, whether we are processing Personal
          Information for direct marketing purposes, you may refer to the
          relevant sections of this document.`
          )}
        </TextDiv>
        <HeaderDiv>
          {tt("uh272", `Data protection rights under GDPR`)}
        </HeaderDiv>
        <TextDiv>
          {tt(
            "uh273",
            `If you are a resident of the European Economic Area (EEA), you have
          certain data protection rights.`
          )}{" "}
          {tt(
            "uh272asd",
            `The Service Provider ensures the enforcement of the principle of lawful, fair and transparent data processing and the right of data subjects to self-determination in accordance with Regulation (EU) 2016/679 of the European Parliament and of the Council (General Data Protection Regulation, hereinafter “GDPR”).`
          )}
        </TextDiv>
        <TextDiv>
          {tt(
            "uh274",
            `Service Provider aims to take
          reasonable steps to allow you to correct, amend, delete, or limit the
          use of your Personal Information.`
          )}{" "}
          {tt(
            "uh275",
            `If you wish to be informed what
          Personal Information we hold about you and if you want it to be
          removed from our systems, please contact us.`
          )}{" "}
          {tt(
            "uh276",
            `In certain circumstances,
          you have the following data protection rights:`
          )}
        </TextDiv>
        <TextIndentFirstDiv>
          {tt(
            "uh276u1",
            `- You have the right to request information about the processing of
          your personal data.`
          )}
        </TextIndentFirstDiv>
        <TextIndentFirstDiv>
          {tt(
            "uh276u2",
            `Upon request, we provide information on your data we manage and process, their source, the purpose, legal basis and duration of the data processing, the name and address of the data processor and the activities related to data processing, and - in case of transfer of your personal data - the legal basis of data transfer and its recipient. We will send the information in a comprehensible form, in writing (by post or electronically) within the shortest time from the submission of your application, but no later than within 30 days.`
          )}
        </TextIndentFirstDiv>
        <TextIndentDiv>
          {tt(
            "uh277",
            `- You have the right to request access to your Personal Information
          that we store and have the ability to access your Personal
          Information.`
          )}
        </TextIndentDiv>
        <TextIndentDiv>
          {tt(
            "uh277c5",
            "We will provide the requested access as soon as possible after the submission of your request, but no later than within 30 days."
          )}
        </TextIndentDiv>
        <TextIndentDiv>
          {tt(
            "uh278",
            `- You have the right to request that we correct any Personal
          Information you believe is inaccurate.`
          )}{" "}
          {tt(
            "uh279",
            `You also have the right to
          request us to complete the Personal Information you believe is
          incomplete.`
          )}
        </TextIndentDiv>
        <TextIndentDiv>
          {tt(
            "uh279kn9",
            "We will ensure that the relevant data is corrected as soon as possible after the submission of your application, but no later than within 30 days."
          )}
        </TextIndentDiv>
        <TextIndentDiv>
          {tt(
            "uh280",
            `- You have the right to request the erase your Personal Information
          under certain conditions of this Privacy Policy.`
          )}
        </TextIndentDiv>
        <TextIndentDiv>
          {tt(
            "uh280mx1",
            `Instead of deleting, we will block your personal information if you
        request it or if the information available to you suggests that the
        deletion would harm your legitimate interests.`
          )}{" "}
          {tt(
            "uh280mx2",
            `Personal data blocked in
        this way can only be processed for as long as the purpose of data
        processing, which precluded the deletion of personal data, exists.`
          )}{" "}
          {tt(
            "uh280mx3",
            `In
        the event of data blocking, such personal data may be processed, with
        the exception of storage, only with your consent, or for the purpose of
        making, enforcing or protecting legal claims, or for protecting the
        rights of another natural or legal person, or in the public interest.`
          )}
        </TextIndentDiv>
        <TextIndentDiv>
          {tt(
            "uh280mx4",
            `The blocking at your request will last as long as the reason you have
        indicated requires the data to be stored.`
          )}{" "}
          {tt(
            "uh280mx5",
            `We will ensure that the data
        concerned is deleted or blocked as soon as possible after the submission
        of your request, but no later than within 30 days.`
          )}
        </TextIndentDiv>
        <TextIndentDiv>
          {tt(
            "uh281",
            `- You have the right to object to our processing of your Personal
          Information.`
          )}
        </TextIndentDiv>
        <TextIndentDiv>
          {tt(
            "bbobx1",
            `You may object to the processing of your personal data,`
          )}
        </TextIndentDiv>
        <TextIndentDiv>
          {tt(
            "bbobx2",
            `(a) where the processing or transfer of personal data is necessary solely for the performance of a legal obligation to which the controller is subject or in the legitimate interest of the controller, the recipient of data or a third party, except in the case of mandatory processing;`
          )}
        </TextIndentDiv>
        <TextIndentDiv>
          {tt(
            "bbobx3",
            `(b) where personal data are used or transmitted for the purposes of direct business acquisition, public opinion polling or scientific research; and`
          )}
        </TextIndentDiv>
        <TextIndentDiv>
          {tt("bbobx4", `c) in other cases specified by law.`)}
        </TextIndentDiv>

        <TextIndentDiv>
          {tt(
            "bbobx5",
            `We will examine your objection to the processing of your data as soon as possible, but no later than 15 days from the submission of your request, and will inform you of the decision in writing.`
          )}
        </TextIndentDiv>

        <TextIndentDiv>
          {tt(
            "bbobx6",
            `If your objection is well-founded, we will terminate the processing of data, including further data collection and data transfer, block the data and notify all those to whom we have previously transferred the personal data affected by the objection about the protest and the action taken on it.`
          )}
        </TextIndentDiv>

        <TextIndentDiv>
          {tt(
            "bbobx7",
            `If you do not agree with our decision, or if we miss the 15-day deadline for making a decision, you can go to court within 30 days of being notified of the decision or the last day of the deadline.`
          )}
        </TextIndentDiv>
        <TextIndentDiv>
          {tt(
            "uh282",
            `- You have the right to seek restrictions on the processing of your
          Personal Information.`
          )}{" "}
          {tt(
            "uh283",
            `When you restrict the processing of your
          Personal Information, we may store it but will not process it further.`
          )}
        </TextIndentDiv>
        <TextIndentDiv>
          {tt(
            "ullox1",
            `Where data is restricted, such personal data may be processed, with the exception of storage, only with your consent, or for the purpose of making, enforcing or protecting legal claims, or for the protection of the rights of another natural or legal person, or in the public interest.`
          )}{" "}
          {tt(
            "ullox2",
            `The restriction on your request will last as long as the reason you have indicated requires the data to be stored.`
          )}
        </TextIndentDiv>

        <TextIndentDiv>
          {tt(
            "ullox3",
            `We will ensure that the data concerned is limited as soon as possible after the submission of your application, but no later than within 30 days.`
          )}
        </TextIndentDiv>
        <TextIndentDiv>
          {tt(
            "uh284",
            `- You have the right to be provided with a copy of the information we
          have on you in a structured, machine-readable and commonly used
          format.`
          )}
        </TextIndentDiv>
        <TextIndentDiv>
          {tt(
            "cwuh284",
            `You have the right to transfer this data to another data controller without being hindered by the data controller if the data processing is based on your consent or contract and the data processing is automated.`
          )}
        </TextIndentDiv>
        <TextIndentDiv>
          {tt(
            "cwuh2841",
            `In exercising your right to data portability, you have the right, if technically feasible, to request the direct transfer of personal data between data controllers.`
          )}
        </TextIndentDiv>
        <TextIndentDiv>
          {tt(
            "cwuh2842",
            `Your request for data portability will be fulfilled within a maximum of 30 days and you will be notified by e-mail to the contact details you have provided.`
          )}
        </TextIndentDiv>
        <TextIndentDiv>
          {tt(
            "uh285",
            `- You also have the right to withdraw your consent at any time where
          Service Provider relied on your consent to process your
          Personal Information.`
          )}
        </TextIndentDiv>
        <TextIndentDiv>
          {tt(
            "rquh285",
            `We will ensure that the data concerned is deleted as soon as possible after the submission of your application, but no later than within 30 days.`
          )}
        </TextIndentDiv>
        <TextIndentDiv>
          {tt(
            "rquh2851",
            `In the case of our newsletter, you can withdraw your consent to receive the newsletter by selecting the 'Unsubscribe' option in the newsletter.`
          )}
        </TextIndentDiv>

        <HeaderDiv>{tt("uh294", `How to exercise these rights`)}</HeaderDiv>
        <TextDiv>
          {tt(
            "uh295",
            `Any requests to exercise your rights can be directed to Service Provider through the contact details provided in this document.`
          )}{" "}
          {tt(
            "uh296",
            `Please note that we may ask you to verify your identity before
          responding to such requests.`
          )}{" "}
          {tt(
            "uh297",
            `Your request must provide sufficient
          information that allows us to verify that you are the person you are
          claiming to be or that you are the authorized representative of such
          person.`
          )}{" "}
          {tt(
            "uh298",
            `You must include sufficient details to allow us to properly
          understand the request and respond to it.`
          )}{" "}
          {tt(
            "uh299",
            `We cannot respond to your
          request or provide you with Personal Information unless we first
          verify your identity or authority to make such a request and confirm
          that the Personal Information relates to you.`
          )}
        </TextDiv>
        <HeaderDiv>{tt("uh300", `Privacy of children`)}</HeaderDiv>
        <TextDiv>
          {tt(
            "uh301",
            `We do not knowingly collect any Personal Information from children
          under the age of 18. If you are under the age of 18, please do not
          submit any Personal Information through our Website or Service.`
          )}{" "}
          {tt(
            "uh302",
            `We
          encourage parents and legal guardians to monitor their children's
          Internet usage and to help enforce this Policy by instructing their
          children never to provide Personal Information through our Website or
          Service without their permission.`
          )}{" "}
          {tt(
            "uh303",
            `If you have reason to believe that a
          child under the age of 18 has provided Personal Information to us
          through our Website or Service, please contact us.`
          )}{" "}
          {tt(
            "uh304",
            `You must also be at
          least 16 years of age to consent to the processing of your Personal
          Information in your country (in some countries we may allow your
          parent or guardian to do so on your behalf).`
          )}
        </TextDiv>
        <HeaderDiv>
          {tt(
            "init1sd",
            `Initiation of a procedure by the National Data Protection and Information Authority`
          )}
        </HeaderDiv>
        <TextDiv>
          {tt(
            "uh286",
            `You have the right to complain to a Data Protection Authority about
          our collection and use of your Personal Information.`
          )}{" "}
          {tt(
            "uh287",
            `For more
          information, please contact your local data protection authority in
          the European Economic Area (EEA). For Hungary it is:`
          )}
        </TextDiv>
        <TextIndentDiv>
          {tt(
            "uh288",
            `*Name:* National Data Protection and Information Authority`
          )}
        </TextIndentDiv>
        <TextIndentDiv>
          {tt("uh289", `*Seat:* 1125 Budapest, Szilágyi Erzsébet fasor 22/C.`)}
        </TextIndentDiv>
        <TextIndentDiv>
          {tt("uh290", `*Postal address:* 1530 Budapest, Pf.: 5.`)}
        </TextIndentDiv>
        <TextIndentDiv>
          {tt("uh291", `*Phone:* +36 (1) 391-1400`)}
        </TextIndentDiv>
        <TextIndentDiv>{tt("uh292", `*Fax:* +36 (1) 391-1410`)}</TextIndentDiv>
        <TextIndentDiv>{tt("uh293", `*E-mail:* li@naih.hu`)}</TextIndentDiv>
        <TextIndentDiv>
          {tt("uh293sd", `*Webpage:* https://naih.hu`)}
        </TextIndentDiv>
        <HeaderDiv>{tt("muh306a", `Judicial enforcement`)}</HeaderDiv>

        <TextDiv>
          {tt(
            "muh306b",
            `If you experience unlawful data processing, it is advisable to send your complaint to the data controller first before initiating legal proceedings, as this will allow the data controller to restore the lawful status by itself.`
          )}
        </TextDiv>

        <TextDiv>
          {tt(
            "muh306c",
            `In the event of a breach of his rights, as well as in the cases specified above, the data subject may take legal action against the data controller.`
          )}{" "}
          {tt("muh306d", `The court is acting out of turn in the case.`)}
        </TextDiv>

        <TextDiv>
          {tt(
            "muh306e",
            `The data controller is obliged to prove that the data processing complies with the provisions of the law.`
          )}
        </TextDiv>

        <TextDiv>
          {tt(
            "muh306f",
            `The trial falls within the jurisdiction of the tribunal.`
          )}{" "}
          {tt(
            "muh306g",
            `The action may, at the option of the person concerned, also be brought before the court of the place where the person concerned is domiciled or resident.`
          )}
        </TextDiv>

        <TextDiv>
          {tt(
            "muh306h",
            `If the court grants the request, the data controller is obliged to provide the information, to correct, block, delete the data, to annul the decision made by automated data processing, and to take into account the data subject's right to protest.`
          )}
        </TextDiv>

        <TextDiv>
          {tt(
            "muh306i",
            `The court may order the publication of its judgment, by publishing the identification data of the data controller, if the interests of data protection and the rights of a larger number of data subjects protected by this Act so require.`
          )}
        </TextDiv>
        <HeaderDiv>{tt("uh305", `Cookies`)}</HeaderDiv>
        <TextDiv>
          {tt(
            "uh306",
            `The Website uses “cookies” to help personalize your online experience.`
          )}{" "}
          {tt(
            "uh307",
            `A cookie is a text file that is placed on your hard disk by a web page
          server.`
          )}{" "}
          {tt(
            "uh308",
            `Cookies cannot be used to run programs or deliver viruses to
          your computer.`
          )}{" "}
          {tt(
            "uh309",
            `Cookies are uniquely assigned to you, and can only be
          read by a web server in the domain that issued the cookie to you.`
          )}
        </TextDiv>
        <TextDiv>
          {tt(
            "uh310",
            `We may use cookies to collect, store, and track information for
          statistical purposes to operate our Website and Services.`
          )}{" "}
          {tt(
            "uh311",
            `You have the
          ability to accept or decline cookies.`
          )}{" "}
          {tt(
            "uh312",
            `Most web browsers automatically
          accept cookies, but you can usually modify your browser setting to
          decline cookies if you prefer.`
          )}{" "}
          {tt(
            "uh313",
            `If you choose to decline cookies, you
          may not be able to fully experience the features of the Website and
          Services.`
          )}{" "}
          {tt(
            "uh314",
            `To learn more about cookies and how to manage them, visit
          internetcookies.org`
          )}{" "}
          {tt(
            "uh314wds",
            `If you want to see the cookies accepted by your browser on our Webpage click on the Cookies option in the footer.`
          )}
        </TextDiv>
        <HeaderDiv>{tt("uh315", `Do Not Track signals`)}</HeaderDiv>
        <TextDiv>
          {tt(
            "uh316",
            `Some browsers incorporate a Do Not Track feature that signals to
          websites you visit that you do not want to have your online activity
          tracked.`
          )}{" "}
          {tt(
            "uh317",
            `Tracking is not the same as using or collecting information
          in connection with a website.`
          )}{" "}
          {tt(
            "uh318",
            `For these purposes, tracking refers to
          collecting personally identifiable information from consumers who use
          or visit a website or online service as they move across different
          websites over time.`
          )}{" "}
          {tt(
            "uh319",
            `How browsers communicate the Do Not Track signal
          is not yet uniform.`
          )}{" "}
          {tt(
            "uh320",
            `As a result, this Website is not yet set up to
          interpret or respond to Do Not Track signals communicated by your
          browser.`
          )}{" "}
          {tt(
            "uh321",
            `Even so, as described in more detail throughout this Policy,
          we limit our use and collection of your personal information.`
          )}
        </TextDiv>
        <HeaderDiv>{tt("uh322", `Email marketing`)}</HeaderDiv>
        <TextDiv>
          {tt(
            "uh323",
            `We offer electronic newsletters to which you may voluntarily subscribe
          at any time.`
          )}{" "}
          {tt(
            "uh324",
            `We are committed to keeping your e-mail address
          confidential and will not disclose your email address to any third
          parties except as allowed in the information use and processing
          section or for the purposes of utilizing a third party provider to
          send such emails.`
          )}{" "}
          {tt(
            "uh325",
            `We will maintain the information sent via e-mail in
          accordance with applicable laws and regulations.`
          )}
        </TextDiv>
        <TextDiv>
          {tt(
            "uh326",
            `In compliance with the CAN-SPAM Act, all e-mails sent from us will
          clearly state who the e-mail is from and provide clear information on
          how to contact the sender.`
          )}{" "}
          {tt(
            "uh327",
            `You may choose to stop receiving our
          newsletter or marketing emails by following the unsubscribe
          instructions included in these emails or by contacting us.`
          )}{" "}
          {tt(
            "uh328",
            `However,
          you will continue to receive essential transactional emails.`
          )}
        </TextDiv>
        <HeaderDiv>{tt("uh329", `Links to other websites`)}</HeaderDiv>
        <TextDiv>
          {tt(
            "uh330",
            `Our Website contains links to other websites that are not owned or
          controlled by us.`
          )}{" "}
          {tt(
            "uh338",
            `Please be aware that we are not responsible for the
          privacy practices of such other websites or third parties.`
          )}{" "}
          {tt(
            "uh339",
            `We
          encourage you to be aware when you leave our Website and to read the
          privacy statements of each and every website that may collect Personal
          Information.`
          )}
        </TextDiv>
        <HeaderDiv>{tt("uh340", `Information security`)}</HeaderDiv>
        <TextDiv>
          {tt(
            "uh341",
            `We secure information you provide on computer servers in a controlled,
          secure environment, protected from unauthorized access, use, or
          disclosure.`
          )}{" "}
          {tt(
            "uh342",
            `We maintain reasonable administrative, technical, and
          physical safeguards in an effort to protect against unauthorized
          access, use, modification, and disclosure of Personal Information in
          its control and custody.`
          )}{" "}
          {tt(
            "uh343",
            `However, no data transmission over the
          Internet or wireless network can be guaranteed.`
          )}{" "}
          {tt(
            "uh344",
            `Therefore, while we
          strive to protect your Personal Information, you acknowledge that (i)
          there are security and privacy limitations of the Internet which are
          beyond our control;`
          )}{" "}
          {tt(
            "uh345",
            `(ii) the security, integrity, and privacy of any
          and all information and data exchanged between you and our Website
          cannot be guaranteed;`
          )}{" "}
          {tt(
            "uh346",
            `and (iii) any such information and data may be
          viewed or tampered with in transit by a third party, despite best
          efforts.`
          )}
        </TextDiv>
        <HeaderDiv>{tt("uh347", `Data breach`)}</HeaderDiv>
        <TextDiv>
          {tt(
            "uh348",
            `In the event we become aware that the security of the Website has been
          compromised or users' Personal Information has been disclosed to
          unrelated third parties as a result of external activity, including,
          but not limited to, security attacks or fraud, we reserve the right to
          take reasonably appropriate measures, including, but not limited to,
          investigation and reporting, as well as notification to and
          cooperation with law enforcement authorities.`
          )}{" "}
          {tt(
            "uh349",
            `In the event of a data
          breach, we will make reasonable efforts to notify affected individuals
          if we believe that there is a reasonable risk of harm to the User as a
          result of the breach or if notice is otherwise required by law.`
          )}{" "}
          {tt(
            "uh350",
            `When
          we do, we will send you an email.`
          )}
        </TextDiv>
        <HeaderDiv>{tt("uh351", `Changes and amendments`)}</HeaderDiv>
        <TextDiv>
          {tt(
            "uh352",
            `We may update this Privacy Policy from time to time in our discretion
          and will notify you of any material changes to the way in which we
          treat Personal Information.`
          )}{" "}
          {tt(
            "uh353",
            `When changes are made, we will revise the
          updated date at the bottom of this page.`
          )}{" "}
          {tt(
            "uh354",
            `We may also provide notice to
          you in other ways in our discretion, such as through contact
          information you have provided.`
          )}{" "}
          {tt(
            "uh355",
            `Any updated version of this Privacy
          Policy will be effective immediately upon the posting of the revised
          Privacy Policy unless otherwise specified.`
          )}{" "}
          {tt(
            "uh356",
            `Your continued use of the
          Website or Services after the effective date of the revised Privacy
          Policy (or such other act specified at that time) will constitute your
          consent to those changes.`
          )}{" "}
          {tt(
            "uh357",
            `However, we will not, without your consent,
          use your Personal Information in a manner materially different than
          what was stated at the time your Personal Information was collected.`
          )}
        </TextDiv>
        <HeaderDiv>{tt("uh358", `Acceptance of this policy`)}</HeaderDiv>
        <TextDiv>
          {tt(
            "uh359",
            `You acknowledge that you have read this Policy and agree to all its
          terms and conditions.`
          )}{" "}
          {tt(
            "uh360",
            `By using the Website or its Services you agree
          to be bound by this Policy.`
          )}{" "}
          {tt(
            "uh361",
            `If you do not agree to abide by the terms
          of this Policy, you are not authorized to use or access the Website
          and its Services.`
          )}
        </TextDiv>
        <HeaderDiv>{tt("uh362", `Contacting us`)}</HeaderDiv>
        <TextDiv>
          {tt(
            "uh363",
            `If you would like to contact us to understand more about this Policy
          or wish to contact us concerning any matter relating to individual
          rights and your Personal Information, you may send an email to
          [*support@inilingo.com*]`
          )}
        </TextDiv>
        <TextDiv>
          {tt("uh364xds", `This document was last updated on March 21, 2022.`)}
        </TextDiv>
      </WrapperDiv>
    </React.Fragment>
  );
};

export default Privacy;
