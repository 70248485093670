import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: any = { translations: {}, counter: 0, paths: [] };

const translationsSlice = createSlice({
  initialState,
  name: "translations",
  reducers: {
    setTranslations: (state: any, action: PayloadAction<any>) => {
      return action.payload;
    },
    setPaths: (state: any, action: PayloadAction<any>) => {
      state.paths = action.payload;
    },
  },
});

export const { setTranslations, setPaths } = translationsSlice.actions;

export default translationsSlice.reducer;
