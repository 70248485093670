//nocheck

import React, { useEffect } from "react";
import styled from "styled-components";

const WrapperDiv = styled.div`
  margin-top: 0px;
  padding: 15px;
  padding-top: 0px;
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 45px;
  background: white;
`;

const TextDiv = styled.div`
  font-size: 18px;
  font-family: var(--font-normal);
  margin-bottom: 10px;
  line-height: 130%;
`;

const HeaderDiv = styled.div`
  font-size: 24px;
  font-family: var(--font-normal);
  font-weight: 700;
  margin-top: 25px;
  margin-bottom: 15px;
  line-height: 1.3;
  color: var(--toolbar-background);
`;

const Eula = (props: any) => {
  const { tt } = props;

  useEffect(() => {
    if (props.subscription) {
      document.getElementById("subscription")?.scrollIntoView();
    }
  }, [props.subscription]);

  return (
    <React.Fragment>
      <WrapperDiv>
        <HeaderDiv id="subscription">
          {tt("t20nsckowe", "Licensed Content End User License Agreement")}
        </HeaderDiv>
        <TextDiv>
          {tt(
            "t23oaasawec1",
            "Content made available through the IniLingo Shop are licensed, not sold, to you."
          )}{" "}
          {tt(
            "t23oaasawec2",
            "Your license to each content (book) is subject to your prior acceptance of this Licensed Application End User License Agreement (“Standard EULA”)."
          )}{" "}
          {tt(
            "t23oaasawec3",
            "Your license to any IniLingo Book under this Standard EULA is granted by NetEnglish Solutions OÜ Hungarian PE (the owner of IniLingo), and your license to any IniLingo Book under this Standard EULA is granted by the Content Provider of that IniLingo Book."
          )}{" "}
          {tt(
            "t23oaasawec4",
            "Any Book that is subject to this Standard EULA is referred to herein as the 'Licensed Book.'"
          )}{" "}
          {tt(
            "t23oaasawec5",
            "The Book Provider or NetEnglish Solutions OÜ Hungarian PE as applicable ('Licensor') reserves all rights in and to the Licensed Book not expressly granted to you under this Standard EULA."
          )}
        </TextDiv>

        <TextDiv>
          {tt(
            "t23oaasawec6",
            "a. Scope of License: Licensor grants to you a nontransferable license to use the Licensed Book within the Application and web service provided by NetEnglish Solutions OÜ as permitted by the Usage Rules."
          )}{" "}
          {tt(
            "t23oaasawec7",
            "The terms of this Standard EULA will govern any content, materials, or services accessible from or purchased within the Licensed Book as well as upgrades provided by Licensor that replace or supplement the original Licensed Book, unless such upgrade is accompanied by a Custom EULA. Except as provided in the Usage Rules, you may not distribute or make the Licensed Book available over a network where it could be used by multiple people at the same time."
          )}{" "}
          {tt(
            "t23oaasawec8",
            "You may not transfer, redistribute or sublicense the Licensed Book."
          )}{" "}
          {tt(
            "t23oaasawec9",
            "You may not copy (except as permitted by this license and the Usage Rules), reverse-engineer, disassemble, attempt to derive the source code of, modify, or create derivative works of the Licensed Book, any updates, or any part thereof (except as and only to the extent that any foregoing restriction is prohibited by applicable law or to the extent as may be permitted by the licensing terms governing use of any non-copyrighted parts included with the Licensed Book)."
          )}
        </TextDiv>

        <TextDiv>
          {tt(
            "t23oaasawec10",
            "b. Consent to Use of Data: You agree that Licensor may collect and use technical data and related information—including but not limited to technical information about your device, system and application software, and peripherals—that is gathered periodically to facilitate the provision of book updates, product support, and other services to you (if any) related to the Licensed Book."
          )}{" "}
          {tt(
            "t23oaasawec11",
            "Licensor may use this information, as long as it is in a form that does not personally identify you, to improve its products or to provide services or technologies to you."
          )}
        </TextDiv>

        <TextDiv>
          {tt(
            "t23oaasawec12",
            "c. Termination. This Standard EULA is effective until terminated by you or Licensor."
          )}{" "}
          {tt(
            "t23oaasawec13",
            "Your rights under this Standard EULA will terminate automatically if you fail to comply with any of its terms."
          )}
        </TextDiv>

        <TextDiv>
          {tt("t23oaasawec14", "d. External Services.")}{" "}
          {tt(
            "t23oaasawec15",
            "The Licensed Book may enable access to Licensor’s and/or third-party services and websites (collectively and individually, 'External Services')."
          )}{" "}
          {tt(
            "t23oaasawec16",
            "You agree to use the External Services at your sole risk."
          )}{" "}
          {tt(
            "t23oaasawec17",
            "Licensor is not responsible for examining or evaluating the content or accuracy of any third-party External Services, and shall not be liable for any such third-party External Services."
          )}{" "}
          {tt(
            "t23oaasawec18",
            "Data displayed by any Licensed Book or External Service, including but not limited to financial, medical and location information, is for general informational purposes only and is not guaranteed by Licensor or its agents."
          )}{" "}
          {tt(
            "t23oaasawec19",
            "You will not use the External Services in any manner that is inconsistent with the terms of this Standard EULA or that infringes the intellectual property rights of Licensor or any third party."
          )}{" "}
          {tt(
            "t23oaasawec20",
            "You agree not to use the External Services to harass, abuse, stalk, threaten or defame any person or entity, and that Licensor is not responsible for any such use."
          )}{" "}
          {tt(
            "t23oaasawec21",
            "External Services may not be available in all languages or in your Home Country, and may not be appropriate or available for use in any particular location."
          )}{" "}
          {tt(
            "t23oaasawec22",
            "To the extent you choose to use such External Services, you are solely responsible for compliance with any applicable laws."
          )}{" "}
          {tt(
            "t23oaasawec23",
            "Licensor reserves the right to change, suspend, remove, disable or impose access restrictions or limits on any External Services at any time without notice or liability to you."
          )}
        </TextDiv>

        <TextDiv>
          {tt(
            "t23oaasawec24",
            "e. NO WARRANTY: YOU EXPRESSLY ACKNOWLEDGE AND AGREE THAT USE OF THE LICENSED BOOK IS AT YOUR SOLE RISK. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, THE LICENSED BOOK AND ANY SERVICES PERFORMED OR PROVIDED BY THE LICENSED BOOK ARE PROVIDED 'AS IS' AND 'AS AVAILABLE,' WITH ALL FAULTS AND WITHOUT WARRANTY OF ANY KIND, AND LICENSOR HEREBY DISCLAIMS ALL WARRANTIES AND CONDITIONS WITH RESPECT TO THE LICENSED BOOK AND ANY SERVICES, EITHER EXPRESS, IMPLIED, OR STATUTORY, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES AND/OR CONDITIONS OF MERCHANTABILITY, OF SATISFACTORY QUALITY, OF FITNESS FOR A PARTICULAR PURPOSE, OF ACCURACY, OF QUIET ENJOYMENT, AND OF NONINFRINGEMENT OF THIRD-PARTY RIGHTS."
          )}{" "}
          {tt(
            "t23oaasawec25",
            "NO ORAL OR WRITTEN INFORMATION OR ADVICE GIVEN BY LICENSOR OR ITS AUTHORIZED REPRESENTATIVE SHALL CREATE A WARRANTY."
          )}{" "}
          {tt(
            "t23oaasawec26",
            "SHOULD THE LICENSED BOOK OR SERVICES PROVE DEFECTIVE, YOU ASSUME THE ENTIRE COST OF ALL NECESSARY SERVICING, REPAIR, OR CORRECTION. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES OR LIMITATIONS ON APPLICABLE STATUTORY RIGHTS OF A CONSUMER, SO THE ABOVE EXCLUSION AND LIMITATIONS MAY NOT APPLY TO YOU."
          )}
        </TextDiv>

        <TextDiv>
          {tt("t23oaasawec27", "f. Limitation of Liability.")}{" "}
          {tt(
            "t23oaasawec28",
            "TO THE EXTENT NOT PROHIBITED BY LAW, IN NO EVENT SHALL LICENSOR BE LIABLE FOR PERSONAL INJURY OR ANY INCIDENTAL, SPECIAL, INDIRECT, OR CONSEQUENTIAL DAMAGES WHATSOEVER, INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS OF PROFITS, LOSS OF DATA, BUSINESS INTERRUPTION, OR ANY OTHER COMMERCIAL DAMAGES OR LOSSES, ARISING OUT OF OR RELATED TO YOUR USE OF OR INABILITY TO USE THE LICENSED BOOK, HOWEVER CAUSED, REGARDLESS OF THE THEORY OF LIABILITY (CONTRACT, TORT, OR OTHERWISE) AND EVEN IF LICENSOR HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES."
          )}{" "}
          {tt(
            "t23oaasawec29",
            "SOME JURISDICTIONS DO NOT ALLOW THE LIMITATION OF LIABILITY FOR PERSONAL INJURY, OR OF INCIDENTAL OR CONSEQUENTIAL DAMAGES,"
          )}{" "}
          {tt("t23oaasawec30", "SO THIS LIMITATION MAY NOT APPLY TO YOU.")}{" "}
          {tt(
            "t23oaasawec31",
            "In no event shall Licensor’s total liability to you for all damages (other than as may be required by applicable law in cases involving personal injury) exceed the amount of thirty dollars ($30.00)."
          )}{" "}
          {tt(
            "t23oaasawec32",
            "The foregoing limitations will apply even if the above stated remedy fails of its essential purpose."
          )}
        </TextDiv>

        <TextDiv>
          {tt(
            "t23oaasawec33",
            "g. You may not use or otherwise export or re-export the Licensed Book except as authorized by Hungarian law and the laws of the jurisdiction in which the Licensed Book was obtained."
          )}
        </TextDiv>
      </WrapperDiv>
    </React.Fragment>
  );
};

export default Eula;
