import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: any = [];

const tabletrSlice = createSlice({
  initialState,
  name: "tabletr",
  reducers: {
    setTabletr: (state: any, action: PayloadAction<any>) => {
      return action.payload;
    },
  },
});

export const { setTabletr } = tabletrSlice.actions;

export default tabletrSlice.reducer;
