import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const projectsSlice = createSlice({
  initialState: [] as any,
  name: "projects",
  reducers: {
    setProjects: (state: any, action: PayloadAction<any>) => {
      return action.payload;
    },
  },
});

export const { setProjects } = projectsSlice.actions;

export default projectsSlice.reducer;
