//student
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import {
  Button,
  CustomInput,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { RootState } from "redux/reducers/_index";
import {
  setConfirmDefault,
  setConfirmOpen,
} from "redux/reducers/reducer.confirm";
import store from "redux/store";

import { tx } from "./globalize";

const CustomPrompt = (props: any) => {
  const confirm = useSelector<RootState, any>((state) => state.confirm);
  const [value, setValue] = useState("");
  const [value2, setValue2] = useState("");
  const [checkbox, setCheckbox] = useState();
  const inputRef = useRef<HTMLInputElement>();
  const [myStyle, setMyStyle] = useState<any>({});

  useEffect(() => {
    setCheckbox(props.defaultChecked || false);
  }, [props.defaultChecked]);

  const help = useSelector<RootState, any>((state) => state.help);

  useEffect(() => {
    if (confirm.openPrompt) {
      setValue(confirm.default || "");
      setValue2(confirm.default2 || "");
      setTimeout(() => {
        inputRef.current?.focus();
      }, 300);

      setMyStyle({ value: "default", label: "default" });
    }
  }, [
    confirm.openPrompt,
    confirm.default,
    confirm.default2,
    confirm.background,
  ]);

  const clickCancel = () => {
    store.dispatch(setConfirmOpen(false));
    store.dispatch(setConfirmDefault(""));
    if (confirm.onCancel) {
      confirm.onCancel();
    }
  };

  const clickOk = () => {
    store.dispatch(setConfirmOpen(false));
    store.dispatch(setConfirmDefault(""));

    if (confirm.onConfirm) {
      if (confirm.checkboxText) {
        confirm.onConfirm(value, checkbox);
      } else if (confirm.backgroundShow) {
        confirm.onConfirm(value, value2, myStyle.name);
      } else if (confirm.content2) {
        confirm.onConfirm(value, value2);
      } else {
        confirm.onConfirm(value);
      }
    }
  };

  const onKeydown = (e: any) => {
    if (e.key === "Enter" && !confirm.textarea) {
      clickOk();
    }
  };

  const changeCheckbox = (e: any) => {
    setCheckbox(e.currentTarget.checked);
  };

  const disabled =
    (confirm.valid && !confirm.valid(value)) ||
    (confirm.valid2 && !confirm.valid2(value2)) ||
    (!confirm.valid &&
      !confirm.valid2 &&
      (!value || (confirm.content2 && !value2)));

  return (
    <React.Fragment>
      <Modal
        isOpen={confirm.openPrompt}
        toggle={clickCancel}
        className={
          "modal-dialog-centered prompter" + (help.open ? " showhelp" : "")
        }
        returnFocusAfterClose={!confirm.noreturn}
      >
        <ModalHeader toggle={clickCancel}>
          {confirm.header || tx("64nwJUg6iL_EXmtd", "Confirmation...")}
        </ModalHeader>
        <ModalBody className="pt-1" id="promptbody">
          {confirm.content ? (
            <div className="mb-50">
              {confirm.content || tx("PEgiipg_dbm7liZO", "Are you sure?")}
            </div>
          ) : null}

          <Input
            value={value}
            onChange={(e: any) => setValue(e.target.value)}
            innerRef={(ref: any) => (inputRef.current = ref)}
            onKeyDown={onKeydown}
            className="mb-1"
          />

          {confirm.content2 ? (
            <React.Fragment>
              <div className="mb-50">
                {confirm.content2 || tx("PEgiipg_dbm7liZO", "Are you sure?")}
              </div>
              <Input
                value={value2}
                onChange={(e: any) => setValue2(e.target.value)}
                onKeyDown={onKeydown}
                className="mb-1"
              />
            </React.Fragment>
          ) : null}
          {confirm.checkboxText ? (
            <CustomInput
              id="checki"
              type="checkbox"
              name="checki"
              label={confirm.checkboxText}
              checked={checkbox}
              onChange={changeCheckbox}
              className={"mb-1"}
            />
          ) : null}
        </ModalBody>
        <ModalFooter style={{ zIndex: 0 }}>
          {confirm.cancelButton ? (
            <Button color="secondary" onClick={clickCancel}>
              {confirm.cancelButton || tx("cancel", "Cancel")}
            </Button>
          ) : null}
          {confirm.confirmButton ? (
            <Button
              color="white"
              className="bg-toolbar text-toolbar"
              onClick={clickOk}
              disabled={disabled}
              id="xpromptbutton"
            >
              {confirm.confirmButton || tx("ok", "OK")}
            </Button>
          ) : null}
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

export default CustomPrompt;
