//student
// Add the Firebase products that you want to use
//tx("asdfasdf1logerr","===Login error")

import {
  setPersistence,
  signInWithEmailAndPassword,
  signOut as signOutOrig,
  browserLocalPersistence,
  browserSessionPersistence,
  initializeAuth,
  GoogleAuthProvider,
  browserPopupRedirectResolver,
} from "firebase/auth";

import { initializeApp } from "firebase/app";

import { getStorage } from "firebase/storage";

import { getFunctions } from "firebase/functions";

import { doc, initializeFirestore } from "firebase/firestore";
import { getDatabase } from "firebase/database";

import { cleanListeners } from "app/App.listeners";

import { setAuthLoaded } from "redux/reducers/reducer.authLoaded";
import store from "redux/store";
import { tx } from "utils/globalize";
import { setCurrentUser } from "redux/reducers/reducer.currentUser";

type User = any;

const config = {
  apiKey: "AIzaSyD6l14VYkA_8ef6Amp3DF59-veE6K94qKQ",
  authDomain: "inilingo0.firebaseapp.com",
  projectId: "inilingo0",
  storageBucket: "inilingo0.appspot.com",
  messagingSenderId: "1013883966114",
  appId: "1:1013883966114:web:7695f346bb5a2a4fbd7dfb",
  databaseURL:
    "https://inilingo0-default-rtdb.europe-west1.firebasedatabase.app",
  measurementId: "G-VHC0W1PL4G",
};

export const firebaseApp = initializeApp(config);

export const auth = initializeAuth(firebaseApp, {
  persistence: browserLocalPersistence,
  popupRedirectResolver: browserPopupRedirectResolver,
});

export const firestore = initializeFirestore(firebaseApp, {
  ignoreUndefinedProperties: true,
});

export const database = getDatabase();

// enableMultiTabIndexedDbPersistence(firestore)
//   .then(() => {})
//   .catch(function (err: any) {
//     if (err.code === "failed-precondition") {
//       // Multiple tabs open, persistence can only be enabled
//       // in one tab at a a time.
//       // ...
//     } else if (err.code === "unimplemented") {
//       // The current browser does not support all of the
//       // features required to enable persistence
//       // ...
//     }
//   });

// export const auth = getAuth(firebaseApp);
// firebase.app().auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);

export interface FreeData {
  [key: string]: any;
}

export const getUserProfileDocument = async (userAuth: User) => {
  if (!userAuth) return;

  const userRef = doc(firestore, `users/${userAuth.uid}`);

  try {
    return userRef;
  } catch (e) {
    console.log(e);
    return null;
  }
};

export const signInWithEmail = (
  email: string,
  password: string,
  rememberme: boolean,
  callbacks: FreeData = {}
) => {
  store.dispatch(setAuthLoaded(false));
  store.dispatch(setCurrentUser(null));

  setPersistence(
    auth,
    rememberme ? browserLocalPersistence : browserSessionPersistence
  )
    .then(function () {
      signInWithEmailAndPassword(auth, email, password)
        .then((res: any) => {
          if (callbacks.success) {
            callbacks.success(res);
          }
        })
        .catch(function (error: any) {
          if (callbacks.failure) {
            callbacks.failure(
              tx("soicxlkkowe", "Login error.") +
                " (" +
                error.code.replace("auth/", "") +
                ")"
            );
          }
        });
    })
    .catch(function (error) {
      callbacks.failure(
        callbacks.failure(
          tx("soicxlkkowe", "Login error.") + " (" + error.code + ")"
        )
      );
    });
};

export const signOut = async () => {
  // removeListeners();
  // removeReaderListener();
  cleanListeners();

  store.dispatch(setAuthLoaded(false));

  localStorage.setItem("currentUser", "");

  await signOutOrig(auth);

  // await FirebaseAuthentication.getIdToken({ forceRefresh: true });

  store.dispatch(setAuthLoaded(true));
  // await firebase.auth().signOut();
  console.log("signed out now");
};

// Create a reference with an initial file path and name

export const cloudStorage = getStorage(firebaseApp);
// export const firestore = getFirestore(firebaseApp);

// Create a reference from a Google Cloud Storage URI

// export const auth = auth;

// if (process.env.NODE_ENV === "development") {
//   firebase.functions().useEmulator("localhost", 5001);
// }

export const functions = getFunctions(firebaseApp);

export const GoogleProvider = new GoogleAuthProvider();
GoogleProvider.setCustomParameters({
  prompt: "select_account",
});

// export const functions = firebase.functions();
